import React, { useState } from "react";

const ReachOut = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(0);

  const setNone = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const sendMail = async () => {
    setNone();
    const body = {
      name: name,
      phone: phone,
      email: email,
      message: message,
    };

    const isNullOrEmpty = (value) =>
      value === null || value === undefined || value === "";

    for (const key in body) {
      if (body.hasOwnProperty(key) && isNullOrEmpty(body[key])) {
        setErrorMessage(`${key.replaceAll("_", " ")} cannot be blank or empty`);
        return null;
      }
    }

    setIsLoading(true);

    const url = `${process.env.REACT_APP_BASE_URL}/send_mail/`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ApiAuthorization: process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();
    setSuccess(response.status);
    if (response.status === 200) {
      setIsLoading(false);
      setSuccessMessage(data["message"]);
      setErrorMessage("");
    } else {
      setIsLoading(false);
      setSuccessMessage("");
      setErrorMessage(data["message"]);
    }
  };
  return (
    <section className="join__community">
      <div className="section__content flexible">
        <div className="text__content contact__left__slider">
          <h3>Reach Out To Us.</h3>
          <p>
            Would you like to know more about what we offer or do you want to
            inquire about joining our community? Send your details and you'd
            recieve a response from us shortly.
          </p>
        </div>
        <div className="form__content contact__right__slider">
          <div>
            <div className="input__group">
              <input
                type="text"
                name="name"
                id="name"
                className="contact__field"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>

            <div className="input__group">
              <input
                type="phone"
                name="phone"
                id="phone"
                className="contact__field"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone number"
              />
            </div>

            <div className="input__group">
              <input
                type="email"
                name="email"
                id="email"
                className="contact__field"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email address"
              />
            </div>

            <div className="input__group">
              <textarea
                name="message"
                className="contact__field"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
                required
              ></textarea>
            </div>
            {success === 200 ? (
              <div className="log success__log">{successMessage}</div>
            ) : (
              <></>
            )}
            {errorMessage.length > 0 ? (
              <div className="log error__log">
                <i class="bx bx-x"></i>
                {errorMessage}
              </div>
            ) : (
              <></>
            )}
            <button className="button__red" onClick={sendMail}>
              {isLoading ? (
                <div className="process">
                  <div id="loader"></div> Submitting your details...
                </div>
              ) : (
                "SUBMIT"
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="bottom__art flexible">
        <div className="semi shape"></div>
        <div className="semi__inverted shape"></div>
        <div className="semi shape"></div>
        <div className="semi__inverted shape"></div>
        <div className="semi shape"></div>
        <div className="semi__inverted shape"></div>
        <div className="semi shape"></div>
        <div className="semi__inverted shape"></div>
      </div>
    </section>
  );
};

export default ReachOut;
