import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import formatDate from "../components/FormatDate";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";

const BlogPost = () => {
  const { blog_title } = useParams();
  const [blogPost, setBlogPost] = useState([]);
  const main_blog_id = window.localStorage.getItem("main_blog_id");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const url = `${process.env.REACT_APP_BASE_URL}/blogs/?mainblog_title=${blog_title.replaceAll("-", " ")}`;

        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            blogid: main_blog_id,
            ApiAuthorization: process.env.REACT_APP_API_KEY,
          },
        });
        const data = await response.json();
        setBlogPost(data);
      } catch (error) {}
    };
    fetchBlog();
    sessionStorage.setItem("hasReloaded", "false");
  }, []);

  return (
    <section class="blog" id="blog_container">
      <div className="event-category">
        <h3>Blog Posts | {blog_title.replaceAll("-", " ")}</h3>
      </div>
      {blogPost.length > 0 ? (
        blogPost.map((element) => (
          <div class="blog_box">
            <div class="blog_image">
              <img src={element.image} />
            </div>
            <div class="blog_content">
              <div class="box__header">
                <div class="txt">{element.title}</div>{" "}
                <i class="bx bxs-chip"></i>
              </div>
              <div class="bottom__border"></div>
              <div class="box__content">
                {element.description.substring(0, 165) + " . . ."}
              </div>
              <div class="box_bottom">
                <Link
                  to={`/blog/${blog_title}/post/${element.title.replaceAll(" ", "-")}`}
                  onClick={() => {
                    window.localStorage.setItem("blog_id", element.id);
                  }}
                >
                  <div class="read__more">
                    Read more <i class="bx bxs-right-arrow"></i>
                  </div>
                </Link>
                <p class="box_date">{formatDate(element.created_at)}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <Loading />
      )}
    </section>
  );
};

export default BlogPost;
