import React, { useState, useEffect } from "react";

import training_image_1 from "../images/training_image_1.jpeg";
import training_image_2 from "../images/training_image_2.jpeg";
import training_image_3 from "../images/training_image_3.jpeg";
import training_image_4 from "../images/training_image_4.jpeg";

const HWService = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const dropdown = [
    {
      title: "Access Control Systems",
      brief_content: `We have reliable security systems which help to protect you and feed you information on the
            state
            of your registered environments, around you and abroad - irrespective of your present location.`,
      content: `
            <div className="hidden__content">
            We have reliable security systems which help to protect you and feed you information on the
            state
            of your registered environments, around you and abroad - irrespective of your present location.
            <div className="hidden__content">
                <p>These Systems are also advanced by the implementation of
                    Internet of Things (IoT) to all our security Systems. A backup database of all
                    monitored and recorded data (Videos, Images, attempted Fingerprint Intrusions, Voice
                    Data, Waves and so on)
                    collated by your Security Systems on vigilance can be kept with us also (if you approve
                    that) but will certainly be
                    logged somewhere in the cloud, accessible to you alone or and other trusted partners or
                    family members of yours.</p>

                <p>
                    Some of our Security Systems include Closed-Circuit Televisions (also known as CCTVs),
                    Fingerprint Protection Systems,
                    Fire Alarm Systems, Intercom Systems, Voice and Data Systems and a few more.
                </p>

                <p>
                    We install these systems in Private Homes,
                    Small and Big Offices, Organizations, Schools and Industries. Very soon, we will be
                    partnering with Top EV Automobile Production
                    Companies, for the inclusion of more powerful systems of these sort into their vehicles
                    for your security, ease and comfort. These
                    special automation systems for Automobiles and Aircrafts are currently being engineered
                    by the concerned team of Engineers, within.
                </p>

                <p>
                    <em>
                        Our rendering of services listed in this section are regardless of which firm
                        handled the Electrical aspects of your building.
                    </em>
                </p>
            </div>
        `,
    },
    {
      title: "All Electrical Services",
      brief_content: `We are professionally into Electrical Services. We only negotiate contracts
            for projects that are yet to be physically executed - not already-built structures.`,
      content: `
            We are professionally into Electrical Services. We only negotiate contracts
                        for projects that are yet to be physically executed - not already-built structures.

                        <div className="hidden__content">
                            <p>
                                Upon submission of your architectural plan, our Structural, Mechanical and Electrical
                                (M&E)
                                Engineers, and other concerned departments will process further necessary designs for
                                the
                                implementation of the Electrical layout and finishing of your project. We will give you
                                our
                                Mechanical and Electrical Design after a few days for reviews before we commence site
                                execution.
                            </p>

                            <p>
                                The quality of our delivery online and on-site will really surprise you as we always
                                ensure
                                service deliveries beyond usual client’s satisfaction. The eternal possibility of this
                                is due
                                to the professional coordination of our COREN-Certified Engineering Departmental Heads
                                and the
                                rich experience of our Office and Site Engineers.
                            </p>

                            <p>
                                <em>
                                    It is however crucial to be aware that the <b>Installation of the Automation
                                        Systems</b>
                                    (such as the remote controlling of all devices in your homes, offices or industries)
                                    will only
                                    be possible if and only if the Electrical Engineering and Electrical Installations
                                    were done by us,
                                    AUTOMACHOICE SYSTEMS Ltd. This is to avoid complications and unnecessary stress on
                                    our Site Engineers
                                    who carry out the Automation Installations. They have to work with our Electrical
                                    Engineering Team from the onset,
                                    even right from the Mechanical and Electrical Design Stage of your amazing project.
                                </em>
                            </p>

                            <p>
                                <em>
                                    Enquiries to this regard can be made by filling the <a href="/contact-us">contact
                                        form</a> on our website,
                                    stating the areas needing clarifications. If something can still be done by our
                                    Engineers
                                    to help with the situation at hand, we will certainly come in for you.
                                </em>
                            </p>
                        </div>`,
    },
    {
      title: "Solar and Inverter Systems",
      brief_content: `We provide professional and reliable Inverter and Solar Energy Systems.
            With the installations of these, the average cost spent on energy annually is greatly reduced
            and economical.`,
      content: `
            We provide professional and reliable Inverter and Solar Energy Systems.
                        With the installations of these, the average cost spent on energy annually is greatly reduced
                        and economical.

                        <div className="hidden__content">
                            <p>
                                Asides installation, maintenance of these systems is key. AUTOMACHOICE SYSTEMS has made
                                that easy to go about.
                                We have a very good database of the locations of all our clients and we deploy our
                                Engineers (who are well qualified)
                                to come around for the maintenance of your Solar Energy and Inverter Systems, <b>in due
                                    time.</b>
                            </p>

                            <p>
                                In consideration of cost and personal choice, clients are free to choose if they want
                                both systems running in their homes
                                or if they just want the installation of Inverter Systems alone. Our esteemed Clients
                                are free to call us later for the
                                installation of the Solar Energy System anytime they consider it fine to.
                            </p>

                            <p>
                                <em>
                                    Our rendering of services listed in this section are regardless of which firm
                                    handled the Electrical aspects of your building.
                                </em>

                            </p>

                        </div>`,
    },
    {
      title: "Agricultural Technological Solutions",
      brief_content: `Over here, we have gone beyond ‘Mechanized Farming’. Our Technological solutions and services
            for the Agricultural Industry is now at the level of ‘Very High Automation’.`,
      content: `
            Over here, we have gone beyond ‘Mechanized Farming’. Our Technological solutions and services
                        for the Agricultural Industry is now at the level of ‘Very High Automation’.

                        <div className="hidden__content">
                            <p>
                                For Crops, Aquaculture, Poultry and Livestock production, we can offer services that
                                will Ease Human Stress,
                                drastically Reduce Cost of Labour and Increase Productivity.
                            </p>

                            <p>
                                Citing now, We build systems for Industrial farms such that water supply, either for
                                crops or for
                                livestock, poultry or fishes can be regulated without human intervention whCoding &
                                Roboticsoever, at all! This will
                                also help to avoid wastage and also to avoid unnecessary thirst. When they are healthy,
                                your business will be very healthy.
                            </p>

                            <p>
                                We also build Robotics Systems that help to gather excretory wastes from poultry birds
                                and stock them them for use as manures in the Crop Farm portion.
                            </p>

                            <p>
                                Automatic Egg Collection Systems can also be installed for the Layers, although the
                                sophistication of this system depends on your financial budget.
                            </p>

                            <p>
                                Automatic Lighting in the farm is also advised especially for Starters (in the poultry
                                section) to make sure they don’t
                                lack warmth at all. We may alternatively build this system such that you can switch on
                                the lights from whatever location
                                you are in and also switch it off when you feel the time is right to. Though, some
                                clients prefer the Lighting System works
                                automatically according to day light and sensor-transduced darkness signals.
                            </p>

                            <p>
                                These are just some basic installations of what we offer for the Agricultural sector.
                                Clients give us works based on what system
                                (solution) they conceive in their mind. We can also help you think. We will still
                                execute it amazingly in real time. Sometimes we propos
                                e the use of drones to perform activities like Seeds Planting, Site Checks and many
                                more. Yours is just to make your challenges known
                                to us! We will proffer solutional alternatives and deliver!
                            </p>

                            <p>
                                In the long run, the Operational Cost of these Systems and the Productivity Yield from
                                the implementation of such
                                ‘High Level Automation’ Technology cannot be compared to manual labour management cost.
                                Implementation of automated systems
                                to your farming business is economical in the long run. It helps you get better yield
                                and more profit.
                            </p>

                        </div>`,
    },
  ];

  const [contentIndex, setContentIndex] = useState(null);

  const handleClick = (index) => {
    const width = window.innerWidth;
    if (width > 800) {
      window.scrollTo({ top: 1000, behavior: "smooth" });
    } else if (width > 600 && width <= 800) {
      window.scrollTo({ top: 1800, behavior: "smooth" });
    } else if (width < 600) {
      window.scrollTo({ top: 1500, behavior: "smooth" });
    }

    setContentIndex(index);
  };

  const handleClose = (index) => {
    const width = window.innerWidth;
    if (width > 800) {
      window.scrollTo({ top: 850, behavior: "smooth" });
    }

    if (width < 800) {
      window.scrollTo({ top: 420, behavior: "smooth" });
    }
    setContentIndex(index);
  };

  return (
    <div>
      <div className="page__header flexible">
        <div className="header__content">
          <i className="bx bx-world"></i>
          <div className="txt">
            Explore the world of our top class service offerings and products.
          </div>
        </div>
      </div>
      <main>
        <section className="manufacture flexible">
          <div className="section__content flexible">
            <div className="txt">
              <h3>Manufacture and Installation of Automated Systems</h3>

              <p>
                This aspect of service and production deals with the
                Manufacturing and Installation of Internet of Things (IoT)
                Systems, Robotic Manipulators to perform menial tasks and also,
                the embedding of Artificial Intelligence into some of these
                systems depending on the solution goal.
              </p>

              <p>
                We have a team of Engineers who specialize in listening to
                whatever challenge you might need technological solution to
                first, and thereafter, work on providing you a variety of
                solutional alternatives to choose from. All alternatives are
                efficient but we are deliberately giving our esteemed customers
                options so that your available cost in hand will not be a
                barrier to project execution. Citing, our range of contracts, as
                regards ‘Manufacturing and Installation of Automated Systems’
                include Private and Industrial Automation, such as automating
                the Lighting System of your Homes and Offices, automating your
                Gates, Garages, Home or Office Curtains, regulating your
                Borehole Water Supply System, and even your Farming Activities.
                The automation of these systems by our Company will actually
                help to increase their efficiency, improve your business
                productivity and reduce operational cost.
              </p>

              <p>
                <em>
                  It is however crucial to be aware that the{" "}
                  <b>Installation of the Automation Systems</b>
                  (such as the remote controlling of all devices in your homes,
                  offices or industries) will only be possible if and only if
                  the Electrical Engineering and Electrical Installations were
                  done by us, AUTOMACHOICE SYSTEMS Ltd. This is to avoid
                  complications and unnecessary stress on our Site Engineers who
                  carry out the Automation Installations. They have to work with
                  our Electrical Engineering Team from the onset, even right
                  from the Mechanical and Electrical Design Stage of your
                  amazing project
                </em>
              </p>

              <p>
                <em>
                  Enquiries to this regard can be made by filling the{" "}
                  <a href="/contact-us">contact form</a> on our website, stating
                  the areas needing clarifications. If something can still be
                  done by our Engineers to help with the situation at hand, we
                  will certainly come in for you.
                </em>
              </p>
            </div>
          </div>
        </section>
        <section className="tech__services">
          {dropdown.map((tech, index) => (
            <div className="service__box">
              <div className="box__header">
                <div className="txt">{tech.title}</div>
                <i className="bx bxs-network-chart"></i>
              </div>
              <div className="bottom__border"></div>
              <div className="box__content">{tech.brief_content}</div>
              <div
                className="read__more"
                onClick={
                  contentIndex === null || contentIndex !== index
                    ? () => handleClick(index)
                    : () => handleClose(null)
                }
              >
                Read more{" "}
                <i
                  className={`${contentIndex === null || contentIndex !== index ? "bx bxs-down-arrow" : "bx bxs-up-arrow"}`}
                ></i>
              </div>
            </div>
          ))}
        </section>

        <section
          className={`read__more__content ${contentIndex === null ? "" : "more_content_animation"} `}
        >
          <div className="header">
            {contentIndex != null ? dropdown[contentIndex].title : ""}
          </div>
          <div className="bottom__border"></div>
          <div className="content">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  contentIndex != null ? dropdown[contentIndex].content : "",
              }}
            />
          </div>

          <div className="close__button" onClick={() => handleClose(null)}>
            Close <i className="bx bx-up-arrow-circle"></i>
          </div>
        </section>

        <section className="hvac__services">
          <div className="inner">
            <div className="header">
              HEATING, VENTILATION AND AIR CONDITIONING (HVAC) SERVICES:{" "}
            </div>
            <div className="bottom__border"></div>

            <div className="content">
              <p>
                We are in partnership with trustworthy and reliable HVAC Systems
                manufacturers. Being that we are first an Automation Company,
                installing our HVAC Systems in your homes, offices, hospitals,
                industries or whatever environment is sure to come with
                automation packages of these systems, if you wish. You can
                actually operate and regulate these Systems from anywhere you
                are! It’s just an extra fair cost. That’s one of the benefits of
                installing these Systems through our esteemed Company -
                Automachoice Systems Ltd.. We are also present 24/7 to attend to
                your requests via mails. In cases of urgent attention, our
                Service Engineers are sure to avail themselves there
                immediately, although not outside work hours, asides cases of
                projects which might require our engineers sleeping and awaking
                on site to complete tasks on time.
              </p>

              <p>
                <em>
                  Our rendering of services listed in this section are
                  regardless of which firm handled the Electrical aspects of
                  your building.
                </em>
              </p>
            </div>
          </div>
        </section>

        <section className="training">
          <div className="content__holder">
            <div className="inner__content">
              <div className="header">
                TRAINING SERVICES - ELECTRICAL ENGINEERING AND AUTOMATION
              </div>

              <div className="content">
                <p>
                  We expositorily and practically impart core knowledge into
                  individuals through our unique systematic training
                  methodologies. Most importantly, we do not train theoretically
                  alone. We train individuals <b>practically</b> with all
                  systems needed to make them proven professionals afterwards.
                  At the completion of each level, there will surely be
                  certification.
                </p>

                <p>
                  Our Training services can either be conducted at any of our
                  branches, at your place of residence (for private coaching) or
                  any location of convenience you decide.
                </p>

                <p>
                  Age is also not a barrier. We train both the Young and the
                  Old. One major arm of our company is the
                  <b>
                    AUTOMATION TRAINING FOR STUDENTS (CODING & ROBOTICS)
                    PROGRAMME.
                  </b>
                </p>

                <p>
                  The CODING & ROBOTICS Programme is a special programme
                  designed to re-orientate and vitalise the minds of our
                  students, towards the field of Automation and Robotics. Today,
                  the thriving professions are those on the path of Coding, IoT,
                  Robotics and Artificial Intelligence. Hence, equipping our
                  children with all these now will make them much needed within
                  the Tech space even before the completion of their Degree
                  Programmes. Today, youths make ends meet in tertiary
                  institutions, freelancing, because they have mastered one or
                  two of these skills. How beautiful will it be for our own
                  children to start getting equipped for the future now and also
                  become partially (or fully) financially independent, from the
                  knowledge of Programming and Robotics Engineering, even before
                  leaving secondary school?
                </p>

                <p>
                  We are open to invitations from schools and any institution
                  having children who would like to incorporate this CODING &
                  ROBOTICS Programme into their operational activities. This
                  programme is designed not to interfere with the existing
                  timetable of the school.
                </p>

                <p>
                  Below are shots of the young ones in Training, under the
                  tutelage of the Director of the Automation Training for
                  Students (CODING & ROBOTICS) Programme.
                </p>

                <div className="image__grid">
                  <div className="training__image">
                    <img src={training_image_1} />
                  </div>

                  <div className="training__image">
                    <img src={training_image_2} />
                  </div>

                  <div className="training__image">
                    <img src={training_image_3} />
                  </div>

                  <div className="training__image">
                    <img src={training_image_4} />
                  </div>
                </div>

                <p>
                  Join us to make the future brighter by contacting us now to
                  enrol your children into this Training Programme, either as an
                  institution or as Parents.{" "}
                  <b>
                    CODING & ROBOTICS Programme, …<em>the future has come.</em>
                  </b>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default HWService;
