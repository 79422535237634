import React, { useState, useEffect } from "react";

import StateData from "../components/StateData";
import success_image from "../images/icon-colored.png";

import Loading from "../components/Loading";

const Register = () => {
  const [levelSelectedOption, setLevelSelectedOption] = useState("");
  const [stateOfOrigin, setStateOfOrigin] = useState("");
  const [stackOption, setStackOption] = useState("");
  const [genderSelectedOption, setGenderSelectedOption] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [stateOfResidence, setStateOfResidence] = useState("");
  const [LGA, setLGA] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [paymentImage, setPaymentImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const MAX_FILE_SIZE = 0.5 * 1024 * 1024; // Maximum file size (5 MB)

  const handleImageChange = (setImage) => (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage(
          "File is too large. Please upload a file smaller than 500kb.",
        );
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      setErrorMessage("");
      const reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const stateData = StateData();

  function toTitleCase(str) {
    return str.toLowerCase().replace(/(?:^|\s|-)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  const [isLoading, setIsLoading] = useState(false);

  const isNullOrEmpty = (value) =>
    value === null || value === undefined || value === "";

  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      event: 1,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      date_of_birth: dob,
      level_of_education: levelSelectedOption,
      email: email,
      phone: phone,
      gender: genderSelectedOption,
      stack: stackOption,
      profile_image: profileImage,
      payment_receipt: paymentImage,
      state_of_origin: stateOfOrigin,
      state_of_residence: stateOfResidence,
      local_government_area: LGA,
      address: address,
    };

    for (const key in body) {
      if (body.hasOwnProperty(key) && isNullOrEmpty(body[key])) {
        setErrorMessage(
          `${toTitleCase(key.replaceAll("_", " "))} cannot be blank or empty`,
        );
        return null;
      }
    }

    setIsLoading(true);

    const url = `${process.env.REACT_APP_BASE_URL}/students/`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ApiAuthorization: process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      if (response.status === 200) {
        setIsLoading(false);
        setSuccess(true);
        setErrorMessage("");
      } else {
        setSuccess(false);
        setErrorMessage(data["message"]);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setIsLoading(false);
      }
    } catch (error) {
      setSuccess(false);
      setErrorMessage("An error occured, Please try again later!");
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsLoading(false);
    }
  };

  return (
    <div>
      <section className="event-layer" id="event-reg">
        <div
          className={`event-register ${success ? "success-hide" : "success-show"}`}
        >
          <div className="register">
            <h3>1MONTH INTENSIVE TRAINING REGISTRATION FORM</h3>
          </div>
          <br />
          <div className="student-info">
            <h3>Student Information</h3>
            <div className="bottom__border"></div>
            <div id="added-children-list" className="added-children"></div>
            <br />
            <div id="individual" className="input__row__2">
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              <div className="image-upload">
                <input
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange(setProfileImage)}
                />
                <label
                  htmlFor="imageUpload"
                  id="imageBox"
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    backgroundColor: "white",
                  }}
                >
                  {profileImage ? (
                    <img
                      id="uploadedImage"
                      src={profileImage}
                      alt="Uploaded"
                      style={{
                        display: "block",
                      }}
                    />
                  ) : (
                    <span style={{ backgroundColor: "white" }}>
                      Click to Upload Passport
                    </span>
                  )}
                </label>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <p style={{ fontSize: "13px", color: "red" }}>
                  Image size must be below 500kb
                </p>
              </div>
              <div className="row_inputs">
                <div className="input__group">
                  <input
                    type="text"
                    name="first-name"
                    id="s-firstname"
                    className="contact__field"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    placeholder="First Name"
                  />
                </div>

                <div className="input__group">
                  <input
                    type="text"
                    name="last-name"
                    id="s-lastname"
                    className="contact__field"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    placeholder="Last Name"
                  />
                </div>

                <div className="input__group">
                  <input
                    type="text"
                    name="middle-name"
                    id="s-middlename"
                    className="contact__field"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                    required
                    placeholder="Middle Name"
                  />
                </div>

                <div className="input__group dob">
                  <label for="date">Date of Birth:</label>
                  <input
                    type="date"
                    name="date"
                    id="s-dob"
                    className="contact__field dob-input"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    required
                    placeholder="Date of Birth"
                  />
                  <br />
                </div>

                <div className="input__group">
                  <input
                    type="text"
                    name="address"
                    id="s-address"
                    className="contact__field"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    placeholder="Address"
                  />
                </div>

                <div class="input__group">
                  <select
                    id="options"
                    value={levelSelectedOption}
                    onChange={(e) => setLevelSelectedOption(e.target.value)}
                    placeholder="Select Level of Education"
                  >
                    <option value="" disabled>
                      Select a level of education...
                    </option>
                    <option value="GRADUATE">Graduate</option>
                    <option value="UNDERGRADUATE">Undergraduate</option>
                    <option value="SECONDARY EDUCATION">
                      Secondary Education
                    </option>
                  </select>
                </div>
                <div className="input__group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="contact__field"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Email address"
                  />
                </div>
                <div className="input__group">
                  <input
                    type="phone"
                    name="phone"
                    id="phone"
                    className="contact__field"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    placeholder="Phone"
                  />
                </div>
                <div class="input__group">
                  <select
                    id="options"
                    value={genderSelectedOption}
                    onChange={(e) => setGenderSelectedOption(e.target.value)}
                    placeholder="Select Gender"
                  >
                    <option value="" disabled>
                      Select your gender...
                    </option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
                <div class="input__group">
                  <select
                    id="options"
                    value={stackOption}
                    onChange={(e) => setStackOption(e.target.value)}
                    placeholder="Select Stack"
                  >
                    <option value="" disabled>
                      Select a stack...
                    </option>
                    <option value="ROBOTICS ENGINEERING">
                      Robotics Engineering
                    </option>
                    <option value="INTERNET OF THINGS">
                      Internet of Things (IoT)
                    </option>
                    <option value="FRONTEND WEB DEVELOPMENT">
                      Frontend Web Development
                    </option>
                    <option value="BACKEND WEB DEVELOPMENT">
                      Backend Web Development
                    </option>
                    <option value="DEVOPS ENGINEERING">
                      Devops Engineering
                    </option>
                    <option value="3D CAD MODELING">3D CAD Modeling</option>
                  </select>
                </div>
                <div class="input__group">
                  <select
                    id="options"
                    value={stateOfOrigin}
                    onChange={(e) => setStateOfOrigin(e.target.value)}
                    placeholder="Select State of Origin"
                  >
                    <option value="" disabled>
                      Select your state of origin...
                    </option>
                    {Object.keys(stateData).map((element) => (
                      <option value={element}>{element}</option>
                    ))}
                  </select>
                </div>
                <div class="input__group">
                  <select
                    id="options"
                    value={stateOfResidence}
                    onChange={(e) => setStateOfResidence(e.target.value)}
                    placeholder="Select State of Residence"
                  >
                    <option value="" disabled>
                      Select your state of residence...
                    </option>
                    {Object.keys(stateData).map((element) => (
                      <option value={element}>{element}</option>
                    ))}
                  </select>
                </div>
                <div class="input__group">
                  <select
                    id="options"
                    value={LGA}
                    onChange={(e) => setLGA(e.target.value)}
                    placeholder="Select L.G.A"
                  >
                    <option value="" disabled>
                      Select your LGA...
                    </option>
                    {stateOfResidence.length > 0 ? (
                      Object.entries(stateData[stateOfResidence]).map(
                        ([state, lgas]) => <option value={lgas}>{lgas}</option>,
                      )
                    ) : (
                      <>Please select a state</>
                    )}
                  </select>
                </div>
              </div>
              <div className="image-upload">
                <input
                  id="paymentUpload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange(setPaymentImage)}
                />
                <label
                  htmlFor="paymentUpload"
                  id="paymentBox"
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    backgroundColor: "white",
                  }}
                >
                  {paymentImage ? (
                    <img
                      id="uploadedPaymentImage"
                      src={paymentImage}
                      alt="Uploaded"
                      style={{
                        display: "block",
                      }}
                    />
                  ) : (
                    <span style={{ backgroundColor: "white" }}>
                      Click to Upload Payment Receipt
                    </span>
                  )}
                </label>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <p style={{ fontSize: "13px", color: "red" }}>
                  Image size must be below 500kb
                </p>
              </div>
            </div>
          </div>
          <div className="submit-btn">
            <button
              id="registerId"
              type="button"
              onClick={handleSubmit}
              className="button__red"
            >
              {isLoading ? <Loading /> : "Register"}
            </button>
          </div>
        </div>
      </section>
      <section className={`registration-success`} id="reg-success-id">
        <div
          className={`success-container ${success ? "success-show" : "success-hide"}`}
        >
          <div className="circle">
            <img src={success_image} alt="" srcset="" />
          </div>
          <div className="text">Registration Successful !</div>
        </div>
      </section>
    </div>
  );
};

export default Register;
