import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <main>
      <section className="who__we__are">
        <div className="icon__box">
          <div className="icon__row">
            <div className="icon">
              <i className="bx bx-chip"></i>
            </div>
            <div className="icon">
              <i className="bx bx-analyse"></i>
            </div>
          </div>
          <div className="icon__row">
            <div className="icon">
              {" "}
              <i className="bx bxs-brain"></i>
            </div>
            <div className="icon">
              <i className="bx bx-braille"></i>
            </div>
          </div>
        </div>

        <div className="text__content">
          <h4>Who we are</h4>
          <p>
            We are a friendly partner committed to freely adding a special
            package to the services and products we render and sell - Joy in
            your hearts.
          </p>

          <p>
            Cool to know, asides our proven local contractors here, we are a
            Private Limited Nigerian Company networked ONLY with
            materials-trusted producing companies and seasoned professionals in
            China, The United States, Italy and India, to give you the very best
            of services you want and maximally satisfactory products you care
            for.
          </p>

          <div className="services">
            Our Areas of Contract Negotiations are enclosed within the spaces
            of:
            <ul>
              <li>Embedded Systemics</li>
              <li>Artificial Intelligence Systemics</li>
              <li>Internet of Things (IoT) Systemics, and</li>
              <li>Software Solutions.</li>
            </ul>
          </div>

          <p>
            Citing, our range of contracts include domestic and industrial
            automation, such as automating the Lighting System of your Homes and
            Offices, automating your Gates, Garages, Home or Office Curtains and
            even your farming activities - to spend less and gain more. We can
            also provide you HVAC (Heating, Ventilation and Air Conditioning)
            Solutions (including your Refrigerating Systems) equipping them with
            high-level automation, to increase their efficiency and reduce
            operational cost.
          </p>

          <p>
            So far, the use of our services and products in your homes, offices
            and industries equal a big reduction in cost spent on Energy
            generation. We majorly achieve this via our Solar and Inverter
            Systems Services. Our esteemed Engineers in that department offer
            tremendous services, with optimal results. We also exist to use our
            Advanced Automated Technologies to provide you reliable and
            extra-sensitive Security and Access Control Systems.
          </p>

          <p>
            To keep things short, just contact us for any challenge you are
            experiencing requiring technology or any needed service/product that
            fall within our range of specialization, we will surely innovate
            varieties of solutional approaches to your needs and problems via
            Automation. Our end solutions are not lifeless systems - They are
            automated systems - simply because we keep{" "}
            <em>
              <b>“Breathing Life into Systems…”.</b>
            </em>
          </p>

          <div className="link__container">
            <a href="/contact-us" className="button__alternate">
              Contact Us
            </a>
          </div>
        </div>
      </section>

      <section class="core__values">
        <header>Our Core Values</header>
        <div class="inner__row">
          <div class="core__box">
            <div class="icon__box">
              <div class="icon__border"></div>
              <i class="bx bx-check"></i>
            </div>

            <div class="core__text">Integrity</div>
          </div>

          <div class="core__box">
            <div class="icon__box">
              <div class="icon__border"></div>
              <i class="bx bxs-been-here"></i>
            </div>

            <div class="core__text">Customer Experience</div>
          </div>

          <div class="core__box">
            <div class="icon__box">
              <div class="icon__border"></div>
              <i class="bx bxs-bullseye"></i>
            </div>

            <div class="core__text">Sustainability</div>
          </div>
        </div>

        <div class="inner__row">
          <div class="core__box">
            <div class="icon__box">
              <div class="icon__border"></div>
              <i class="bx bxs-meteor"></i>
            </div>

            <div class="core__text">Soft Works</div>
          </div>

          <div class="core__box">
            <div class="icon__box">
              <div class="icon__border"></div>
              <i class="bx bx-unite"></i>
            </div>

            <div class="core__text">Reliability</div>
          </div>
        </div>
      </section>

      <section class="mission">
        <div class="content__container">
          <div class="inner__tag">Our Mission</div>
          <div class="inner__text">
            AUTOMACHOICE SYSTEMS’ mission is to innovate varieties of solutional
            approaches to needs and problems via automation.
          </div>
        </div>
      </section>

      <section class="vision">
        <div class="content__container">
          <div class="inner__tag">Our Vision</div>
          <div class="inner__text">
            AUTOMACHOICE SYSTEMS’ vision is to accelerate a new era of
            implementation of Advanced Digital Technology and Artificial
            Intelligence to ease Mechanical Labour and also have Tech Fun, from
            the African Space to beyond.
          </div>
        </div>
      </section>
    </main>
  );
};

export default AboutUs;
