import React, { useState, useEffect } from "react";

const SWService = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const dropdown = [
    {
      title: "Frontend Web Development",
      brief_content: `We are responsible for crafting the user interface, optimizing the user experience, and ensuring 
            the performance, accessibility, and compatibility of web applications. Our technical expertise 
            are essential in creating web experiences that engage and delight users while meeting the project's 
            objectives and requirements.`,
      content: `
            <div className="hidden__content">
                            We are responsible for crafting the user interface, optimizing the user experience, and ensuring 
                            the performance, accessibility, and compatibility of web applications. Our technical expertise 
                            are essential in creating web experiences that engage and delight users while meeting the project's 
                            objectives and requirements.
                <p>
                    <span>User Interface (UI) Design:</span> We are proficient in translating design concepts and mockups into interactive, visually appealing user interfaces. This involves selecting appropriate color schemes, typography, and layout techniques to ensure an aesthetically pleasing and user-friendly design.
                </p>
                <p>
                    <span>User Experience (UX) Optimization:</span> We focus on enhancing the overall user experience by ensuring that the application or website is intuitive and easy to navigate. This involves creating responsive designs that adapt to various screen sizes and devices, as well as optimizing for performance and speed.
                </p>
                <p>
                    <span>Coding Skills:</span> We possess strong proficiency in HTML, CSS, and JavaScript, the core technologies used for building the frontend of web applications. We write clean, maintainable, and semantically correct code that adheres to industry standards and best practices.
                </p>
                <p>
                    <span>Frameworks and Libraries:</span> We are skilled in using frontend frameworks and libraries such as React, Angular, or Vue.js to streamline development, manage state, and create dynamic user interfaces. We stay up-to-date with the latest advancements in these technologies.
                </p>
                <p>
                    <span>Cross-Browser Compatibility:</span> We ensure that the application or website works consistently across different web browsers and platforms. This involves testing and debugging to resolve compatibility issues.
                </p>
                <p>
                    <span>Responsive Design:</span> We design and implement responsive layouts that adapt gracefully to various screen sizes, including desktops, tablets, and smartphones. Responsive design is essential for providing a seamless user experience on all devices.
                </p>
                <p>
                    <span>Performance Optimization:</span> We optimize the frontend for speed and performance by minimizing page load times, reducing the use of unnecessary resources, and implementing lazy loading techniques.
                </p>
                <p>
                    <span>Version Control and Git:</span> We are proficient in using version control systems like Git to track changes in code, collaborate with team members, and manage code repositories.
                </p>
                <p>
                    <span>Continuous Integration/Continuous Deployment (CI/CD):</span> We are familiar with CI/CD pipelines to automate testing, build processes, and deployments, ensuring a smooth and reliable development workflow.
                </p>
                <p>
                    <span>Debugging and Troubleshooting:</span> We have strong debugging skills to identify and resolve frontend issues quickly. This includes using browser developer tools and other debugging techniques.
                </p>
                <p>
                    <span>Security Awareness:</span> We are vigilant about security concerns and implement best practices to protect against common web vulnerabilities, such as cross-site scripting (XSS) and cross-site request forgery (CSRF).
                </p>
                <p>
                    <span>Performance Monitoring:</span> We use various tools and techniques to monitor frontend performance in production environments, identifying bottlenecks and optimizing code accordingly.
                </p>
            </div>
        `,
    },
    {
      title: "Backend Web Development",
      brief_content: `We offer vital services including designing system architecture, managing data, developing APIs and
            server logic, ensuring performance and security, handling scalability, integrating third-party services, monitoring,
            troubleshooting, and providing comprehensive documentation.`,
      content: `
            We offer vital services including designing system architecture, managing data, developing APIs and
                        server logic, ensuring performance and security, handling scalability, integrating third-party services, monitoring,
                        troubleshooting, and providing comprehensive documentation.
            
            <div class="hidden__content">
            <p>
                <span>Robust System Architecture:</span> We design, develop, and maintain the core architecture of the software system. Our expertise
                allows us to create a robust, scalable, and efficient infrastructure that can handle a high volume of users and data.
            </p>

            <p>
                <span>Data Management:</span> We are responsible for managing the storage, retrieval, and manipulation of data. We ensure that
                databases are designed optimally, queries are efficient, and data is organized securely.
            </p>

            <p>
                <span>API Development:</span> We create and maintain the APIs (Application Programming Interfaces) that allow different components of
                the software to communicate with each other. This includes designing API endpoints, handling requests and responses, and
                ensuring data integrity.
            </p>

            <p>
                <span>Server-Side Logic:</span> We implement the server-side logic that powers the application. This includes business logic,
                authentication, authorization, and security measures to protect against threats like SQL injection and cross-site
                scripting (XSS) attacks.
            </p>

            <p>
                <span>Performance Optimization:</span> We constantly monitor and optimize the backend to ensure fast response times and minimal
                downtime. This involves load balancing, caching, and other techniques to improve overall system performance.
            </p>

            <p>
                <span>Scalability:</span> We plan for scalability from the start and implement strategies to handle increased traffic and user loads
                as the application grows. This may involve horizontal scaling, vertical scaling, or the use of cloud services.
            </p>

            <p>
                <span>Security:</span> We are responsible for the security of the system's backend, including data encryption, user authentication,
                and protection against common security vulnerabilities. We stay up-to-date with the latest security threats and
                implement best practices to mitigate risks.
            </p>

            <p>
                <span>Database Management:</span> We design and maintain the database schema, optimize queries, and ensure data consistency and
                integrity. Regular backups and disaster recovery plans are also part of our responsibilities.
            </p>

            <p>
                <span>Integration:</span> We facilitate integrations with third-party services and external APIs, enabling the application to
                interact seamlessly with external systems and data sources.
            </p>

            <p>
                <span>Monitoring and Troubleshooting:</span> We set up monitoring tools to proactively identify and resolve issues before they impact
                the user experience. When problems do arise, we are adept at diagnosing and fixing them swiftly.
            </p>
        </div>`,
    },
    {
      title: "Custom Software Development",
      brief_content: `We are highly skilled professionals who design, develop, test, and maintain software applications and systems. Our
            contributions extend far beyond just writing lines of code; we are the architects of the digital world, crafting
            solutions that power the modern age.`,
      content: `
            We are highly skilled professionals who design, develop, test, and maintain software applications and systems. Our
            contributions extend far beyond just writing lines of code; we are the architects of the digital world, crafting
            solutions that power the modern age.
            <div class="hidden__content">
                            <p>
                                <span>Innovative Problem Solving:</span> At the core of our work, we excel in solving complex problems. Whether it's creating a new
                                application or improving an existing one, we meticulously analyze requirements and devise innovative solutions to
                                address them.
                            </p>

                            <p>
                                <span>Software Development:</span> We are proficient in various programming languages and frameworks. Our coding skills enable us to
                                transform concepts into functioning software, ensuring it meets the highest standards of functionality, reliability, and
                                performance.
                            </p>

                            <p>
                                <span>System Architecture:</span> We specialize in designing the overall structure and components of software systems. Our
                                architectural decisions lay the foundation for scalable, efficient, and maintainable solutions.
                            </p>

                            <p>
                                <span>Code Quality Assurance:</span> We are committed to producing clean, readable, and well-documented code. Our expertise in
                                software engineering best practices ensures that the codebase is robust and easy to maintain.
                            </p>

                            <p>
                                <span>Testing and Quality Control:</span> We rigorously test software to identify and rectify defects. Our dedication to quality
                                control ensures that applications are bug-free, secure, and provide an exceptional user experience.
                            </p>

                            <p>
                                <span>Continuous Learning:</span> Technology is constantly evolving, and we stay ahead of the curve by continuously learning and
                                adapting to new tools, languages, and best practices. Our commitment to learning enables us to offer cutting-edge
                                solutions.
                            </p>

                            <p>
                                <span>Problem Diagnosis and Troubleshooting:</span> When issues arise in software systems, we are adept at diagnosing and resolving
                                them efficiently. Our troubleshooting skills are essential in maintaining the reliability of applications.
                            </p>

                            <p>
                                <span>Project Management:</span> We often play a role in project management, ensuring that software development projects are
                                delivered on time and within budget. Our expertise in project planning and coordination is invaluable.
                            </p>

                            <p>
                                <span>Ethical and Secure Software:</span> We prioritize ethical coding practices and cybersecurity. Our commitment to security and
                                data privacy safeguards the integrity of software systems and protects user information.
                            </p>

                        </div>`,
    },
    {
      title: "Web Hosting and Domain Management",
      brief_content: `We offer a comprehensive suite of services designed to keep websites running smoothly, securely, and efficiently. Our
            expertise covers domain registration, hosting setup, server maintenance, security, support, and performance
            optimization, ensuring that our clients' online assets are in capable hands.`,
      content: `
            We offer a comprehensive suite of services designed to keep websites running smoothly, securely, and efficiently. Our
            expertise covers domain registration, hosting setup, server maintenance, security, support, and performance
            optimization, ensuring that our clients' online assets are in capable hands.
            <div class="hidden__content">
            <p>
                <span>Domain Registration and Management:</span> We assist clients in selecting and registering domain names that align with their brand and goals and 
                we also manage domain renewals, ensuring that the web address remains active and accessible.
            </p>
            <p>
                <span>Website Hosting:</span>We offer a range of hosting solutions, from shared hosting for small websites to dedicated servers for high-traffic
                applications.
                We set up, configure, and maintain web servers to ensure optimal performance and security.
            </p>
            <p>
                <span>Server Configuration and Maintenance:</span>
                We handle server operating system installation and configuration, making sure it's up-to-date and secure.
                We regularly monitor and maintain servers to guarantee uptime and troubleshoot any issues that may arise.
            </p>
            <p>
                <span>Security and Data Protection:</span>
                We implement security measures to safeguard websites and data against cyber threats and attacks.
                We create and manage SSL certificates to provide secure connections for e-commerce sites and sensitive data.
            </p>
            <p>
                <span>Content Management System (CMS) Support:</span>
                We assist in the installation, configuration, and maintenance of popular CMS platforms like WordPress, Drupal, or
                Joomla.
                We ensure CMSs are updated, secure, and performant.
            </p>
            <p>
                <span>Backup and Disaster Recovery:</span>
                We establish regular backup schedules to protect data and provide recovery solutions in case of unforeseen incidents.
                We create redundancy in hosting environments to minimize downtime in the event of server failures.
            </p>
            <p>
                <span>Email Hosting and Management:</span>
                We set up email services associated with the client's domain, including configuration, maintenance, and troubleshooting.
                We manage email server security, spam filtering, and ensure reliable email delivery.
            </p>
            <p>
                <span>Performance Optimization:</span>
                We continuously monitor website performance and make necessary adjustments to improve loading times and user experience.
                We optimize server resources to ensure websites run efficiently, even during traffic spikes.
            </p>
            <p>
                <span>Technical Support and Troubleshooting:</span>
                We provide round-the-clock technical support to address client concerns and resolve any hosting or domain-related issues
                promptly.
                We investigate and resolve server or website-related problems, minimizing disruptions.
            </p>
            <p>
                <span>Scalability and Resource Planning:</span>
                We assist clients in assessing their resource requirements and recommend appropriate hosting upgrades or downgrades as
                needed.
                We plan for future scalability to accommodate growth and changes in web traffic and demand.
            </p>

            </div>`,
    },
    {
      title: "Software Maintainance and Support",
      brief_content: `We work diligently to maintain software integrity, performance, security, and user satisfaction while ensuring it adapts to the evolving technology landscape and business
            needs. Our efforts are instrumental in prolonging the lifecycle of software applications and maximizing their value to
            organizations and end-users.`,
      content: `
            We work diligently to maintain software integrity, performance, security, and user satisfaction while ensuring it adapts to the evolving technology landscape and business
            needs. Our efforts are instrumental in prolonging the lifecycle of software applications and maximizing their value to
            organizations and end-users.
            <div class="hidden__content">
            <p>
             <span>Bug Fixing:</span>
             We diligently identify and address any software bugs or glitches that may arise during the software's lifecycle. This
             includes investigating, replicating, and resolving issues to maintain a smooth user experience.
            </p>
            
            <p>
             <span>Updates and Upgrades:</span>
             We keep the software up-to-date by implementing regular updates and upgrades. This ensures that the software remains
             compatible with evolving technology and security standards.
            </p>
            
            <p>
             <span>Performance Optimization:</span>
             We are committed to improving the software's performance by identifying bottlenecks and inefficiencies, then
             implementing optimizations to enhance its speed and responsiveness.
            </p>
            
            <p>
             <span>Security Enhancements:</span>
             Our role includes safeguarding the software against security threats. We regularly apply security patches and updates to
             protect against vulnerabilities, keeping sensitive data and user information secure.
            </p>

            <p>
             <span>Compatibility Testing:</span>
             We ensure that the software functions seamlessly across various platforms, operating systems, and devices. This
             guarantees a consistent user experience for all users.
            </p>

            <p>
             <span>User Support:</span>
             We provide assistance to end-users who encounter issues while using the software. We may offer troubleshooting guidance,
             answer questions, and help users understand the software's features and functionalities.
            </p>

            <p>
             <span>Data Backup and Recovery:</span>
             We establish and maintain robust backup and recovery procedures to ensure that critical data is protected and can be
             restored in case of data loss or system failures.
            </p>
 
         </div>`,
    },
  ];

  const [contentIndex, setContentIndex] = useState(null);

  const handleClick = (index) => {
    const width = window.innerWidth;
    if (width > 800) {
      window.scrollTo({ top: 1000, behavior: "smooth" });
    } else if (width > 600 && width <= 800) {
      window.scrollTo({ top: 1800, behavior: "smooth" });
    } else if (width < 600) {
      window.scrollTo({ top: 1500, behavior: "smooth" });
    }

    setContentIndex(index);
  };

  const handleClose = (index) => {
    const width = window.innerWidth;
    if (width > 800) {
      window.scrollTo({ top: 850, behavior: "smooth" });
    }

    if (width < 800) {
      window.scrollTo({ top: 420, behavior: "smooth" });
    }
    setContentIndex(index);
  };

  return (
    <div>
      <div className="page__header flexible">
        <div className="header__content">
          <i className="bx bx-world"></i>
          <div className="txt">
            Explore the world of our top class service offerings and products.
          </div>
        </div>
      </div>
      <main>
        <section className="tech__services">
          {dropdown.map((tech, index) => (
            <div className="service__box">
              <div className="box__header">
                <div className="txt">{tech.title}</div>
                <i className="bx bxs-network-chart"></i>
              </div>
              <div className="bottom__border"></div>
              <div className="box__content">{tech.brief_content}</div>
              <div
                className="read__more"
                onClick={
                  contentIndex === null || contentIndex !== index
                    ? () => handleClick(index)
                    : () => handleClose(null)
                }
              >
                Read more{" "}
                <i
                  className={`${contentIndex === null || contentIndex !== index ? "bx bxs-down-arrow" : "bx bxs-up-arrow"}`}
                ></i>
              </div>
            </div>
          ))}
        </section>

        <section
          className={`read__more__content ${contentIndex === null ? "" : "more_content_animation"} `}
        >
          <div className="header">
            {contentIndex != null ? dropdown[contentIndex].title : ""}
          </div>
          <div className="bottom__border"></div>
          <div className="content">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  contentIndex != null ? dropdown[contentIndex].content : "",
              }}
            />
          </div>

          <div className="close__button" onClick={() => handleClose(null)}>
            Close <i className="bx bx-up-arrow-circle"></i>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SWService;
