import React from "react";

const formatDate = (originalDate) => {
    const dateObj = new Date(originalDate);
  
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
  
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = (hours % 12) || 12;
  
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
  
    const formattedDate = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm} | ${month} ${day}, ${year}`;
  
    return formattedDate;
}

export default formatDate;