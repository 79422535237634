import "../src/css/home.css";
import "../src/css/general.css";
import "../src/owlcarousel/css/owl.carousel.min.css";
import "../src/owlcarousel/css/owl.theme.default.min.css";
import "../src/css/plugin.css";
import "../src/css/services.css";
import "../src/css/about.css";
import "../src/css/contact.css";
import "../src/css/blog.css";
import "../src/css/blog-post.css";
import "../src/css/trainings.css";
import "../src/css/events.css";
import "../src/css/register.css";

import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BlogProvider } from "./components/BlogDataContext";

import Home from "./pages/Home";
import Layout from "./components/Layout";
import SWService from "./pages/SWServices";
import HWService from "./pages/HWServices";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Blogs from "./pages/Blogs";
import BlogPost from "./pages/BlogPost";
import Post from "./pages/Post";
import Trainings from "./pages/Trainings";
import TrainingPage from "./pages/TrainingPage";
import Event from "./pages/Event";
import Register from "./pages/Register";

const App = () => {
  return (
    <BrowserRouter>
      <BlogProvider>
        <Routes>
          <Route path="/">
            <Route index element={<Layout children={<Home />} />} />
          </Route>
          <Route
            path="/sw-services"
            element={<Layout children={<SWService />} />}
          />
          <Route
            path="/services"
            element={<Layout children={<HWService />} />}
          />
          <Route path="/events" element={<Layout children={<Event />} />} />
          <Route path="/trainings/">
            <Route path="" element={<Layout children={<Trainings />} />} />
            <Route
              path=":training_stack"
              element={<Layout children={<TrainingPage />} />}
            />
          </Route>
          <Route path="/about-us" element={<Layout children={<AboutUs />} />} />
          <Route
            path="/event/register"
            element={<Layout children={<Register />} />}
          />
          <Route
            path="/contact-us"
            element={<Layout children={<Contact />} />}
          />
          <Route path="/blog/">
            <Route path="" element={<Layout children={<Blogs />} />} />
            <Route path=":blog_title/post/">
              <Route path="" element={<Layout children={<BlogPost />} />} />
              <Route
                path=":post_title"
                element={<Layout children={<Post />} />}
              />
            </Route>
          </Route>
        </Routes>
      </BlogProvider>
    </BrowserRouter>
  );
};

export default App;
