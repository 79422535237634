import React from "react";
import white_icon from '../images/white-icon.png'


const Footer = () => {
    return (
        <footer>
            <div className="inner__section flexible">
                <div className="footer__section">
                    <div className="section__header">INQUIRIES</div>

                    <ul>
                        <li>
                            <a href="#">Projects</a>
                        </li>

                        <li>
                            <a href="/about-us">About Us</a>
                        </li>

                        <li>
                            <a href="#">Community</a>
                        </li>
                    </ul>
                </div>

                <div className="footer__section">
                    <div className="section__header">CONTACT</div>

                    <ul>
                        <li>
                            <a href="/contact-us">By mail</a>
                        </li>

                        <li>
                            <a href="tel:+2349032075159">By phone</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="inner__section flexible">
                <div className="footer__section">
                    <div className="section__header">LEGAL</div>

                    <ul>
                        <li>
                            <a href="#">Privacy Policy</a>
                        </li>

                        <li>
                            <a href="#">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>

                <div className="footer__section">
                    <div className="section__header">FOLLOW US</div>

                    <div className="social__media__links">
                        {/* <a href="#"><i className='bx bxl-facebook-circle'></i></a> */}
                        <a href="https://twitter.com/automachoice?s=09"><i className='bx bxl-twitter'></i></a>
                        <a href="https://www.instagram.com/automachoice?r=nametag"><i className='bx bxl-instagram'></i></a>
                    </div>
                </div>
            </div>

            <div className="inner__section">
                <div className="footer__section">
                    <div className="inner__content">
                        <div className="logo">
                            <img src={white_icon} alt="Logo" />
                        </div>
                        <div className="rights">&copy; 2023 ALL RIGHTS RESERVED</div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;