import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";

import h_1 from "../img/header-slideshow/hv-1.jpeg";
import h_2 from "../img/header-slideshow/hv-2.jpeg";
import h_3 from "../img/header-slideshow/hv-3.jpeg";
import h_4 from "../img/header-slideshow/hv-4.jpeg";
import h_5 from "../img/header-slideshow/hv-5.jpeg";
import h_6 from "../img/header-slideshow/hv-6.jpeg";
import h_7 from "../img/header-slideshow/hv-7.jpeg";
import main from "../img/main.jpg";
import ps_1 from "../img/photos-speak/psv-1.jpeg";
import ps_2 from "../img/photos-speak/psv-2.jpeg";
import ps_3 from "../img/photos-speak/psv-3.jpeg";
import ps_4 from "../img/photos-speak/psv-4.jpeg";
import ps_5 from "../img/photos-speak/psv-5.jpeg";
import ps_6 from "../img/photos-speak/psv-6.jpeg";
import ps_7 from "../img/photos-speak/psv-7.jpeg";
import ps_8 from "../img/photos-speak/psv-8.jpeg";
import ps_9 from "../img/photos-speak/psv-9.jpeg";

import event_video from "../img/videos/event_video.mp4";

import level_x1 from "../img/level-x/Copy of Robotics programming.jpg";
import level_x2 from "../img/level-x/Copy of Automation.jpeg";
import level_x3 from "../img/level-x/Copy of Certification.jpg";
import level_x4 from "../img/level-x/Copy of Games and mind-blowing activities.jpg";
import level_x5 from "../img/level-x/Copy of Group Projects.jpg";
import level_x6 from "../img/level-x/Copy of Simple Robotics systems.jpg";

import level_y1 from "../img/level-y/Community-PNG-Image-300x287.png";
import level_y2 from "../img/level-y/Copy of Advanced Robotics.jpg";
import level_y3 from "../img/level-y/Copy of Certification.jpg";
import level_y4 from "../img/level-y/Copy of Engineering principles-min.jpg";
import level_y5 from "../img/level-y/Copy of Fun projects.jpg";
import level_y6 from "../img/level-y/Copy of Games and mind-blowing activities.jpg";
import level_y7 from "../img/level-y/Copy of Robotics programming.jpg";
import level_y8 from "../img/level-y/Copy of Simple Robotics designs.png";

import level_z1 from "../img/level-z/Advanced Robotics.jpg";
import level_z2 from "../img/level-z/Advanced robotics 1.jpg";
import level_z3 from "../img/level-z/Certification.jpg";
import level_z4 from "../img/level-z/Copy of Community-PNG-Image-300x287.png";
import level_z5 from "../img/level-z/Copy of Robotics programming.jpg";
import level_z6 from "../img/level-z/Drones.jpg";
import level_z7 from "../img/level-z/Entrepreneur.jpg";
import level_z8 from "../img/level-z/Final projects.png";
import level_z9 from "../img/level-z/Home Automation.jpg";
import level_z10 from "../img/level-z/Internship.jpg";
import level_z11 from "../img/level-z/Public speaking.jpg";
import level_z12 from "../img/level-z/mechatronics.jpg";

import partners_1 from "../img/partners/aero_drones.jpeg";
import partners_2 from "../img/partners/automacom.jpeg";
import partners_3 from "../img/partners/bigga.jpeg";
import partners_4 from "../img/partners/fire_in_me.jpeg";
import partners_5 from "../img/partners/fruitful_ville.jpeg";
import partners_6 from "../img/partners/meadowhall_logo.png";
import partners_7 from "../img/partners/unique_friends.jpeg";

import star from "../images/icons/star.svg";
import jeson from "../images/jeson.jpg";
import lily from "../images/lily.jpg";
import ron from "../images/ron.jpg";

const Home = () => {
  const [visitorId, setVisitorId] = useState(null);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const getVisitorId = () => {
      let id = window.localStorage.getItem("visitor_id");
      if (!id) {
        id = generateUniqueUUID();
        window.localStorage.setItem("visitor_id", id);
      }
      setVisitorId(id);
    };

    const generateUniqueUUID = () => {
      let d = new Date().getTime();
      const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        },
      );
      return uuid;
    };

    getVisitorId();

    let activeIndex = 0;
    let nextImageIndex = 0;

    const infiniteSlider = () => {
      let bannerImages = Array.from(
        document.querySelectorAll(".banner-images img"),
      );

      const slideImages = () => {
        if (activeIndex + 1 === bannerImages.length) {
          nextImageIndex = 0;
        } else {
          nextImageIndex = activeIndex + 1;
        }

        setTimeout(() => {
          bannerImages[nextImageIndex].style.opacity = 0.8;
          bannerImages[activeIndex].style.opacity = 0.8;

          bannerImages[activeIndex].classList.add("out-of-view");
          bannerImages[activeIndex].style.removeProperty("opacity");

          activeIndex += 1;
          bannerImages[nextImageIndex].classList.remove("out-of-view");

          bannerImages[nextImageIndex].style.opacity = 1;

          if (activeIndex === bannerImages.length) {
            activeIndex = 0;
            nextImageIndex = 0;
            resetBanners();
          }

          slideImages(); // Recursive call here
        }, 2000);
      };

      slideImages();
    };

    const resetBanners = () => {
      let bannerItems = Array.from(
        document.querySelectorAll(".banner-images img"),
      );
      bannerItems.forEach((item, index) => {
        if (index === 0) {
          item.classList.remove("out-of-view");
        } else {
          item.classList.add("out-of-view");
        }
      });
    };

    window.addEventListener("DOMContentLoaded", getVisitorId);
    infiniteSlider();

    return () => {
      window.removeEventListener("DOMContentLoaded", getVisitorId);
    };
  }, []);

  useEffect(() => {
    const intersectionCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!entry.target.classList.contains("is-observed")) {
            const children = Array.from(entry.target.children);
            const mainNumbers = [];
            children.forEach((child, index) => {
              // Add index parameter
              const statNumber = parseInt(child.innerText);
              mainNumbers.push(statNumber);

              const statCount = child.querySelector(".colour");
              statCount.innerText = 0 + "+";
              updateStatCount(index); // Pass index to updateStatCount function

              function updateStatCount(index) {
                // Add index parameter
                const countData = parseInt(statCount.innerText);
                statCount.innerText = "";
                statCount.innerText = countData + 1 + "+";
                const currentCount = parseInt(statCount.innerText);
                if (currentCount < mainNumbers[index]) {
                  setTimeout(() => {
                    updateStatCount(index); // Pass index recursively
                  }, 5);
                }
              }
            });

            entry.target.classList.add("is-observed");
            observer.unobserve(entry.target);
          }
        }
      });
    };

    const observer = new IntersectionObserver(intersectionCallback, {
      threshold: 0.5,
    });

    // Observe your target element here
    const target = document.getElementById("statNumbers");
    if (target) {
      observer.observe(target);
    }

    // Clean up the observer
    return () => {
      observer.disconnect();
    };
  }, []);

  //Photo Speak

  const images = [ps_5, ps_6, ps_7, ps_8, ps_9, ps_1, ps_2, ps_4, ps_3];
  const [current, setCurrent] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const carouselContainerRef = useRef(null);

  const updateDimensions = () => {
    if (carouselContainerRef.current) {
      setSlideWidth(carouselContainerRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const nextSlide = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  };

  //Level Slides
  const level_x_images = [
    {
      image: level_x1,
      label: "Embedded Systems Programming",
    },
    {
      image: level_x2,
      label: "Introductory Lessons on Automation",
    },
    {
      image: level_x3,
      label: "Certificate of Completion and Competence",
    },
    {
      image: level_x4,
      label: "Game Sessions",
    },
    {
      image: level_x5,
      label: "Group Projects and Tasks",
    },
    {
      image: level_x6,
      label: "Building of Simple Robotic Systems",
    },
    {
      image: level_x1,
      label: "Embedded Systems Programming",
    },
    {
      image: level_x2,
      label: "Introductory Lessons on Automation",
    },
    {
      image: level_x3,
      label: "Certificate of Completion and Competence",
    },
    {
      image: level_x4,
      label: "Game Sessions",
    },
    {
      image: level_x5,
      label: "Group Projects and Tasks",
    },
    {
      image: level_x6,
      label: "Building of Simple Robotic Systems",
    },
  ];

  const level_y_images = [
    {
      image: level_y1,
      label: "Social Skills Training",
    },
    {
      image: level_y2,
      label: "Advanced Robotics and Complex Systems",
    },
    {
      image: level_y3,
      label: "Certificate of Completion",
    },
    {
      image: level_y4,
      label: "Engineering Principles and Governance",
    },
    {
      image: level_y5,
      label: "Fun Projects",
    },
    {
      image: level_y6,
      label: "Game Sessions",
    },
    {
      image: level_y7,
      label: "Embedded Systems Programming",
    },
    {
      image: level_y8,
      label: "Robotics Designs",
    },
    {
      image: level_y1,
      label: "Social Skills Training",
    },
    {
      image: level_y2,
      label: "Advanced Robotics and Complex Systems",
    },
    {
      image: level_y3,
      label: "Certificate of Completion",
    },
    {
      image: level_y4,
      label: "Engineering Principles and Governance",
    },
  ];

  const level_z_images = [
    {
      image: level_z1,
      label: "Advanced Robotics and Complex Systems",
    },
    {
      image: level_z2,
      label: "Advanced Robotics and Complex Systems",
    },
    {
      image: level_z3,
      label: "Certificate of Completion and Competence",
    },
    {
      image: level_z4,
      label: "Social Skills Training",
    },
    {
      image: level_z5,
      label: "Embedded Systems Programming",
    },
    {
      image: level_z6,
      label: "Drone Principles, Designs and Concepts",
    },
    {
      image: level_z7,
      label: "Entrepreneurship Skills",
    },
    {
      image: level_z8,
      label: "Final Level Project",
    },
    {
      image: level_z9,
      label: "Home Automation",
    },
    {
      image: level_z10,
      label: "Access to Internship, Mentorship and Competitions",
    },
    {
      image: level_z11,
      label: "Public Speaking and Critical Thinking Session",
    },
    {
      image: level_z12,
      label: "Mechatronics",
    },
  ];

  const partners_list = [
    partners_1,
    partners_2,
    partners_3,
    partners_4,
    partners_5,
    partners_6,
    partners_7,
    partners_1,
    partners_2,
    partners_3,
    partners_4,
    partners_5,
  ];

  const [levelCurrent, setLevelCurrent] = useState(0);
  const numImages = level_x_images.length;

  const levelNextSlide = () => {
    setLevelCurrent(levelCurrent === numImages - 1 ? 0 : levelCurrent + 1);
    addNumbers(levelCurrent);
  };

  const levelPrevSlide = () => {
    setLevelCurrent(levelCurrent === 0 ? numImages - 1 : levelCurrent - 1);
    addNumbers(levelCurrent);
  };

  const [activeList, setActiveList] = useState([0, 1, 2, 3]);

  function addNumbers(startIndex) {
    const numbers = [];
    for (let i = 0; i < 4; i++) {
      const num = (startIndex + i) % 6;
      numbers.push(num);
    }
    setActiveList(numbers);
  }

  useEffect(() => {
    const intervalId = setInterval(() => levelNextSlide(), 5000);

    return () => clearInterval(intervalId);
  }, [levelCurrent]);

  const ratingSectionRef = useRef(null);

  useEffect(() => {
    const ratingsObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            !entry.target.classList.contains("animated-section")
          ) {
            entry.target.classList.add("animated-section");
            ratingsObserver.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.45 },
    );

    const ratingSection = ratingSectionRef.current;
    ratingsObserver.observe(ratingSection);

    return () => ratingsObserver.unobserve(ratingSection);
  }, []);

  const sections = [
    {
      title: "How long does the process take?",
      content: `
                <p>
                    <div>Our training is divided into 3 levels:</div>
                    <div><b>CODING & ROBOTICS Level X</b> - 5 Months: Introductory Robotics lessons and training</div>
                    <div><b>CODING & ROBOTICS Level Y</b> - 8 Months: Advanced Robotics and Automation Engineering training sessions with projects.</div>
                    <div><b>CODING & ROBOTICS Level Z</b> - 12 Months: Advanced and more complex projects, access to internships, mentorships and competitions.</div>
                </p>`,
    },
    {
      title: "Can I register two or more children?",
      content:
        "Yes, you can register more than one child or a group of students. We’ll provide good discounts for that.",
    },
    {
      title: "What are the requirements for learning?",
      content: `<div>The requirements for learning are:</div>
                      <ol>
                          <li>A student from Age 6 above</li>
                          <li>He/she must have a ready heart to learn.</li>
                          <li>Robotics Kits</li>
                      </ol>`,
    },
    {
      title: "Where are you located?",
      content:
        "We have our programme in several centres in all local governments across Lagos State and Ogun State.",
    },
    {
      title: "Is it an online or Physical training?",
      content:
        "It’s an in-depth physical training program for optimal results. If you’re insistent on online, you could kindly message us.",
    },
    {
      title: "Can I pay on installment?",
      content: "Yes, Send a message to know more.",
    },
    {
      title: "Will my child get certified?",
      content:
        "Absolutely yes, your child will get a certificate of completion after final exams.",
    },
    {
      title: "Do you offer private robotics training?",
      content:
        "Yes, we do offer both private and general teaching, depending on your choice.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleSection = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      <div className="container">
        <div className="image-container banner-images">
          <img src={h_1} className="out-of-view" alt="Banner Image 1" />
          <img src={h_2} className="out-of-view" alt="Banner Image 2" />
          <img src={h_7} className="out-of-view" alt="Banner Image 3" />
          <img src={h_3} className="out-of-view" alt="Banner Image 4" />
          <img src={h_4} className="out-of-view" alt="Banner Image 5" />
          <img src={h_5} className="out-of-view" alt="Banner Image 6" />
          <img src={h_6} className="out-of-view" alt="Banner Image 7" />

          <div className="paragraph-container">
            <div className="page-width">
              <h1>CODING AND ROBOTICS</h1>
              <p className="paragraph-one">
                Bring your children’s dreams to reality in building functional
                and real-world Robots and IoT Systems from scratch!
              </p>
              <a href="/contact-us" className="button__red">
                Request a Call
              </a>
            </div>
          </div>
        </div>
      </div>

      <section className="first-text-container">
        <div className="second-part page-width">
          <div className="details content">
            <p>
              {" "}
              <span>T</span>he CODING & ROBOTICS program has trained hundreds of
              students in Tech fields of Robotics, Artificial Intelligence and
              Internet of Things (IoT), and have also taught them to build fun
              projects like Drones, RC Airplanes, RC Cars and many more, with
              well-structured curricular, to give your children/students
              hands-on and practical experience in building highly-functional
              robots and automation projects.
            </p>
            <p>
              This is a special programme designed to re-orientate and vitalise
              the minds of our children/students towards the fields of
              Automation, Robotics and Artificial Intelligence.
            </p>
            <p>Background Music By: Spyro</p>
          </div>

          <div className="second-part-image content">
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <ReactPlayer
                url={event_video}
                style={{ position: "absolute", top: 0, left: 0 }}
                width="100%"
                height="100%"
                playing={true}
                loop={true}
                controls={true}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="future-section">
        <div className="create-part page-width">
          <div className="create content">
            <p className="header">Create the best future for your kids.</p>
            <p className="quote">
              “Tell me and I forget, teach me and I may remember, involve me and
              I learn.” - Benjamin Franklin.
            </p>
            <p>
              The CODING & ROBOTICS program is an arm of Automachoice Systems
              Ltd., aimed at equipping students or children of any age with
              Jet-Age skills with state-of-the-art creativity for them to become
              more useful to themselves, families, the society and the nation at
              large.
            </p>
          </div>

          <div className="second-part-border content">
            <div className="line"></div>
            <p>
              We provide a healthy and conducive environment for peer-to-peer
              learning, to produce the best in them.
            </p>
            <p>
              Today, the thriving professions are those in the path of Coding,
              IoT, Robotics and Artificial Intelligence. Hence, equipping your
              kids/students with all these now will make them much sought after
              within the Tech space, even before the completion of their Degree
              programmes in tertiary institutions.
            </p>
          </div>
        </div>
      </section>

      <div className="bottom-line page-width"></div>

      <section className="programme-section page-width">
        <p className="title">CODING & ROBOTICS programme at a Glance:</p>
        <div className="numbers" id="statNumbers">
          <div className="numbers-element">
            <span className="colour">2000+</span>
            <span className="below">Enrolled students</span>
          </div>

          <div className="numbers-element">
            <span className="colour">100+</span>
            <span className="below">Qualified Tutors</span>
          </div>

          <div className="numbers-element">
            <span className="colour">1000+</span>
            <span className="below">Classes Held</span>
          </div>
        </div>

        <div className="button-holder">
          <a href="/contact-us" className="button-blue">
            Request a Call
          </a>
        </div>
      </section>

      <section className="photos-section page-width">
        <p className="title">Photos Speak</p>
        <div className="title-line"></div>
        <div class="photo-slider">
          <div className="slideshow owl-carousel owl-theme owl-loaded owl-drag">
            <div ref={carouselContainerRef} className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: `translate3d(-${current * slideWidth + 10 * current}px, 0, 0)`,
                  transition: "all 0.5s ease",
                  width: `${(images.length + 1) * slideWidth}px`,
                }}
              >
                {images.map((img, index) => (
                  <div
                    key={index}
                    className={`owl-item ${index === current ? "active" : ""}`}
                    style={{
                      width: current === 8 && index === 8 ? 0 : slideWidth,
                      marginRight: "10px",
                    }}
                  >
                    <div className="slider-item">
                      <img
                        style={{ width: `${slideWidth}px` }}
                        src={img}
                        alt={`Slide ${index}`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="owl-dots">
              {images.map((_, idx) => (
                <button
                  key={idx}
                  className={`owl-dot ${idx === current ? "active" : ""}`}
                  onClick={() => setCurrent(idx)}
                >
                  <span></span>
                </button>
              ))}
            </div>

            <div className="custom-button custom-prev" onClick={prevSlide}>
              <i
                className="fa-solid fa-circle-arrow-left"
                aria-hidden="true"
              ></i>
            </div>
            <div className="custom-button custom-next" onClick={nextSlide}>
              <i
                className="fa-solid fa-circle-arrow-right"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </section>
      <section className="backup-section page-width">
        <p className="title">Why CODING & ROBOTICS ?</p>
        <p className="first-paragraph">
          Automation Training for Students (CODING & ROBOTICS) has a lot of
          exciting privileges and opportunities to make your kids another{" "}
          <span>Silas Adekunle</span> (highest paid Robotics Engineer) or{" "}
          <span>Elon Musk</span> of Nigeria.
        </p>
        <div className="list-section">
          <ul>
            <li>
              Hands-on Projects on Robotics, Drones, RC Airplanes and Home
              Automation.
            </li>
            <li>Access to Robotics Competitions within Nigeria and Abroad.</li>
            <li>Well-structured Curricular and Advanced teaching gadgets.</li>
            <li>Experienced, Competent and Certified tutors.</li>
            <li>Conducive and Convenient learning environment.</li>
            <li>Mentorship and Internship Opportunities.</li>
            <li>Mind training Games.</li>
            <li>Financial and Business Skills.</li>
            <li>Industrial Excursions.</li>
            <li>Certificate of Completion and Competence.</li>
            <li>
              Awards and Prizes to the best five (5) students of each cohort.{" "}
            </li>
          </ul>
        </div>
      </section>

      <section className="quote-section">
        <div className="page-width">
          <i className="fa-solid fa-quote-left"></i>
          <p className="main-quote">
            We look forward to seeing your kids and students become salient
            participators in this dynamic tech revolution we are orchestrating,
            via our CODING & ROBOTICS Programme.
          </p>
          <i className="fa-solid fa-quote-right"></i>
          <p className="quote-director">
            Victor J. OLUYOLE — CODING & ROBOTICS Programme Coordinator.
          </p>
          <div className="button-holder-two">
            <a href="/contact-us" className="button-blue">
              Request a Call
            </a>
          </div>
        </div>
      </section>

      <section className="c-section page-width">
        <div className="overview content">
          <p>Curriculum Overview</p>
        </div>

        <div className="levels content">
          <div className="line"></div>
          <p>
            The CODING & ROBOTICS Programme is divided into levels for the best
            learning experience;
          </p>
        </div>
      </section>

      <section className="month-section page-width">
        <p className="title">CODING & ROBOTICS LEVEL X:</p>
        <p>
          This is a 5-Month intensive training scheme on Robotics, Artificial
          Intelligence and Internet of Things (IoT). This is the first stage for
          your kids to be Robotics and Automation Engineers. The training
          session will include:
        </p>

        <div className="level-slides-container">
          <div className="level-slides owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: `translate3d(-${levelCurrent * (408.75 + 10)}px, 0, 0)`,
                  transition: "all 0.25s ease 0s",
                  width: "7752px",
                }}
              >
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div className="x-slide-container">
                    <div className="slider-image">
                      <img src={level_x_images[5].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_x_images[5].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_x_images[4].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_x_images[4].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_x_images[3].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_x_images[3].label}
                    </div>
                  </div>
                </div>
                {level_x_images.map((level_x_list, index) => (
                  <div
                    key={index}
                    className={`owl-item ${activeList.includes(index) ? "active" : ""}`}
                    style={{ width: "408.75px", marginRight: "10px" }}
                  >
                    <div>
                      <div className="slider-image">
                        <img src={level_x_list.image} alt="Level X1" />
                      </div>
                      <div className="slider-label">{level_x_list.label}</div>
                    </div>
                  </div>
                ))}
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_x_images[0].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_x_images[0].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_x_images[1].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_x_images[1].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_x_images[2].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_x_images[2].label}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Dots */}
          <div className="slideshow owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-dots">
              <button
                className={`owl-dot ${levelCurrent <= 6 ? "active" : ""}`}
                onClick={() => setLevelCurrent(0)}
              >
                <span></span>
              </button>
              <button
                className={`owl-dot ${levelCurrent > 6 ? "active" : ""}`}
                onClick={() => setLevelCurrent(11)}
              >
                <span></span>
              </button>
            </div>
          </div>
          {/* Custom navigation buttons */}
          <div className="custom-button custom-prev" onClick={levelPrevSlide}>
            <i className="fa-solid fa-circle-arrow-left" aria-hidden="true"></i>
          </div>
          <div className="custom-button custom-next" onClick={levelNextSlide}>
            <i
              className="fa-solid fa-circle-arrow-right"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </section>

      <section className="robo-section">
        <div className="dash"></div>
        <i className="fa-solid fa-robot"></i>
        <div className="dash"></div>
      </section>

      <section className="y-section page-width">
        <p className="title">CODING & ROBOTICS LEVEL Y:</p>
        <p>
          This is a 8-Month intensive and advanced training scheme in Robotics,
          Artificial Intelligence and Internet of Things (IoT) complexities.
          This is the final and a key stage for your children to be Robotics and
          Automation Engineers. It includes:
        </p>

        <div className="level-slides-container">
          <div className="level-slides owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: `translate3d(-${levelCurrent * (408.75 + 10)}px, 0, 0)`,
                  transition: "all 0.25s ease 0s",
                  width: "7752px",
                }}
              >
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div className="x-slide-container">
                    <div className="slider-image">
                      <img src={level_y_images[7].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_y_images[7].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_y_images[6].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_y_images[6].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_y_images[5].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_y_images[5].label}
                    </div>
                  </div>
                </div>
                {level_y_images.map((level_y_list, index) => (
                  <div
                    key={index}
                    className={`owl-item ${activeList.includes(index) ? "active" : ""}`}
                    style={{ width: "408.75px", marginRight: "10px" }}
                  >
                    <div>
                      <div className="slider-image">
                        <img src={level_y_list.image} alt="Level X1" />
                      </div>
                      <div className="slider-label">{level_y_list.label}</div>
                    </div>
                  </div>
                ))}
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_y_images[0].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_y_images[0].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_y_images[1].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_y_images[1].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_y_images[2].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_y_images[2].label}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Dots */}
          <div className="slideshow owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-dots">
              <button
                className={`owl-dot ${levelCurrent <= 6 ? "active" : ""}`}
                onClick={() => setLevelCurrent(0)}
              >
                <span></span>
              </button>
              <button
                className={`owl-dot ${levelCurrent > 6 ? "active" : ""}`}
                onClick={() => setLevelCurrent(11)}
              >
                <span></span>
              </button>
            </div>
          </div>
          {/* Custom navigation buttons */}
          <div className="custom-button custom-prev" onClick={levelPrevSlide}>
            <i className="fa-solid fa-circle-arrow-left" aria-hidden="true"></i>
          </div>
          <div className="custom-button custom-next" onClick={levelNextSlide}>
            <i
              className="fa-solid fa-circle-arrow-right"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </section>
      <section className="robo-section">
        <div className="dash"></div>
        <i className="fa-solid fa-robot"></i>
        <div className="dash"></div>
      </section>

      <section className="y-section page-width">
        <p className="title">CODING & ROBOTICS LEVEL Z:</p>
        <p>
          This is a 12-Month intensive and advanced training scheme in Robotics,
          Artificial Intelligence and Internet of Things (IoT) complexities.
          This is the final and a key stage for your children to be Robotics and
          Automation Engineers. It includes:
        </p>

        <div className="level-slides-container">
          <div className="level-slides owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: `translate3d(-${levelCurrent * (408.75 + 10)}px, 0, 0)`,
                  transition: "all 0.25s ease 0s",
                  width: "7752px",
                }}
              >
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div className="x-slide-container">
                    <div className="slider-image">
                      <img src={level_z_images[11].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_z_images[11].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_z_images[10].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_z_images[10].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_z_images[9].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_z_images[9].label}
                    </div>
                  </div>
                </div>
                {level_z_images.map((level_z_list, index) => (
                  <div
                    key={index}
                    className={`owl-item ${activeList.includes(index) ? "active" : ""}`}
                    style={{ width: "408.75px", marginRight: "10px" }}
                  >
                    <div>
                      <div className="slider-image">
                        <img src={level_z_list.image} alt="Level X1" />
                      </div>
                      <div className="slider-label">{level_z_list.label}</div>
                    </div>
                  </div>
                ))}
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_z_images[0].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_z_images[0].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_z_images[1].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_z_images[1].label}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "408.75px", marginRight: "10px" }}
                >
                  <div>
                    <div className="slider-image">
                      <img src={level_z_images[2].image} alt="Level X1" />
                    </div>
                    <div className="slider-label">
                      {level_z_images[2].label}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Dots */}
          <div className="slideshow owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-dots">
              <button
                className={`owl-dot ${levelCurrent <= 6 ? "active" : ""}`}
                onClick={() => setLevelCurrent(0)}
              >
                <span></span>
              </button>
              <button
                className={`owl-dot ${levelCurrent > 6 ? "active" : ""}`}
                onClick={() => setLevelCurrent(11)}
              >
                <span></span>
              </button>
            </div>
          </div>
          {/* Custom navigation buttons */}
          <div className="custom-button custom-prev" onClick={levelPrevSlide}>
            <i className="fa-solid fa-circle-arrow-left" aria-hidden="true"></i>
          </div>
          <div className="custom-button custom-next" onClick={levelNextSlide}>
            <i
              className="fa-solid fa-circle-arrow-right"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </section>

      <section className="robo-section">
        <div className="dash"></div>
        <i className="fa-solid fa-robot"></i>
        <div className="dash"></div>
      </section>

      <section className="y-section page-width">
        <center>
          <p className="title">PARTNERS & AFFILIATES:</p>
        </center>
        <p></p>
        <div className="level-slides-container">
          <div className="level-slides owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: `translate3d(-${levelCurrent * 350}px, 0, 0)`,
                  transition: "all 0.25s ease 0s",
                  width: "6300px",
                }}
              >
                <div className="owl-stage">
                  <div
                    className="owl-item cloned"
                    style={{
                      width: "250px",
                      marginRight: "50px",
                      marginLeft: "50px",
                    }}
                  >
                    <div className="partners">
                      <div className="partner-images">
                        <img src={partners_list[7]} alt="partner label" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{
                      width: "250px",
                      marginRight: "50px",
                      marginLeft: "50px",
                    }}
                  >
                    <div className="partners">
                      <div className="partner-images">
                        <img src={partners_list[6]} alt="partner label" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{
                      width: "250px",
                      marginRight: "50px",
                      marginLeft: "50px",
                    }}
                  >
                    <div className="partners">
                      <div className="partner-images">
                        <img src={partners_list[5]} alt="partner label" />
                      </div>
                    </div>
                  </div>
                  {partners_list.map((partner, index) => (
                    <div
                      key={index}
                      className={`owl-item ${activeList.includes(index) ? "active" : ""}`}
                      style={{
                        width: "250px",
                        marginRight: "50px",
                        marginLeft: "50px",
                      }}
                    >
                      <div className="partners">
                        <div className="partner-images">
                          <img src={partner} alt="partner label" />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div
                    className="owl-item cloned"
                    style={{
                      width: "250px",
                      marginRight: "50px",
                      marginLeft: "50px",
                    }}
                  >
                    <div className="partners">
                      <div className="partner-images">
                        <img src={partners_list[2]} alt="partner label" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{
                      width: "250px",
                      marginRight: "50px",
                      marginLeft: "50px",
                    }}
                  >
                    <div className="partners">
                      <div className="partner-images">
                        <img src={partners_list[1]} alt="partner label" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{
                      width: "250px",
                      marginRight: "50px",
                      marginLeft: "50px",
                    }}
                  >
                    <div className="partners">
                      <div className="partner-images">
                        <img src={partners_list[0]} alt="partner label" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slideshow owl-carousel owl-theme owl-loaded owl-drag">
              <div className="owl-dots">
                <button
                  className={`owl-dot ${levelCurrent <= 6 ? "active" : ""}`}
                  onClick={() => setLevelCurrent(0)}
                >
                  <span></span>
                </button>
                <button
                  className={`owl-dot ${levelCurrent > 6 ? "active" : ""}`}
                  onClick={() => setLevelCurrent(11)}
                >
                  <span></span>
                </button>
              </div>
            </div>
            {/* Custom navigation buttons */}
            <div className="custom-button custom-prev" onClick={levelPrevSlide}>
              <i
                className="fa-solid fa-circle-arrow-left"
                aria-hidden="true"
              ></i>
            </div>
            <div className="custom-button custom-next" onClick={levelNextSlide}>
              <i
                className="fa-solid fa-circle-arrow-right"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </section>

      <section className="parents-review">
        <p className="heading page-width">
          What are Parents and Students saying?
        </p>
        <div className="line"></div>

        <section className="rating-section">
          <div
            className="rating-items box-section page-width"
            ref={ratingSectionRef}
            id="ratingSection"
          >
            <div className="box testimonial-one">
              <div className="stars">
                {[...Array(5)].map((_, index) => (
                  <img key={index} src={star} alt="Star" />
                ))}
              </div>
              <div className="word">
                “Lectus, nonummy et. Occaecat delectus erat, minima dapibus
                ornare nunc, autem.”
              </div>
              <div className="ron">
                <div className="person">
                  <img src={ron} alt="Ron Burnwood" />
                </div>
                <div className="image-name">
                  <p>Ron Burnwood</p>
                  <p>Student</p>
                </div>
              </div>
            </div>

            <div className="box testimonial-two">
              <div className="stars">
                {[...Array(5)].map((_, index) => (
                  <img key={index} src={star} alt="Star" />
                ))}
              </div>
              <div className="word">
                “Lectus, nonummy et. Occaecat delectus erat, minima dapibus
                ornare nunc, autem.”
              </div>
              <div className="lily">
                <div className="person">
                  <img src={lily} alt="Lily Granger" />
                </div>
                <div className="image-name">
                  <p>Lily Granger</p>
                  <p>Student</p>
                </div>
              </div>
            </div>

            <div className="box testimonial-three">
              <div className="stars">
                {[...Array(5)].map((_, index) => (
                  <img key={index} src={star} alt="Star" />
                ))}
              </div>
              <div className="word">
                “Lectus, nonummy et. Occaecat delectus erat, minima dapibus
                ornare nunc, autem.”
              </div>
              <div className="jeson">
                <div className="person">
                  <img src={jeson} alt="Jeson Foxx" />
                </div>
                <div className="image-name">
                  <p>Jeson Foxx</p>
                  <p>Parent</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="last-section">
          <div className="button-holder-three">
            <a href="/contact-us" className="button-blue">
              Request a Call
            </a>
          </div>
        </section>

        <section className="faq-section">
          <header className="section-heading">
            <h3>F.A.Q</h3>
            <p className="caption">
              These are questions you might be asking...
            </p>
          </header>
          <div className="questions">
            {sections.map((section, index) => (
              <div
                key={index}
                className={`accordion-main ${openIndex === index ? "content-visible" : ""}`}
                style={{
                  height:
                    openIndex === index
                      ? index === 0
                        ? "238px"
                        : "180px"
                      : "64px",
                  transition: "height 300ms ease-in-out",
                }}
              >
                <div
                  className="accordion-header"
                  onClick={() => toggleSection(index)}
                >
                  <FontAwesomeIcon
                    icon={openIndex === index ? faCaretUp : faCaretRight}
                    className="caret-icon"
                  />
                  <span>{section.title}</span>
                </div>
                <div
                  ref={(el) => (contentRefs.current[index] = el)}
                  className="accordion-content"
                  style={{
                    height:
                      openIndex === index
                        ? `${contentRefs.current[index]?.scrollHeight}px`
                        : "0px",
                    overflow: "hidden",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: section.content }} />
                </div>
              </div>
            ))}
          </div>
        </section>
      </section>
    </div>
  );
};

export default Home;
