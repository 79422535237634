import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import ReachOut from "./ReachOut";

const Layout = ({ children }) => {
  return (
    <div>
      <header>
        <Header />
      </header>
      <main>
        {children}
        <ReachOut />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
