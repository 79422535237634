import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useBlogContext } from "../components/BlogDataContext";
import formatDate from "../components/FormatDate";
import Loading from "../components/Loading";

const Blogs = () => {
  const blog = useBlogContext();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <section class="blog" id="blog_container">
      <div className="event-category">
        <h3>Blogs</h3>
      </div>
      {blog.length > 0 ? (
        blog.map((element) => (
          <div class="blog_box">
            <div class="blog_image">
              <img src={element.image} alt="cover" />
            </div>
            <div class="blog_content">
              <div class="box__header">
                <div class="txt">{element.title}</div>{" "}
                <i class="bx bxs-chip"></i>
              </div>
              <div class="bottom__border"></div>
              <div class="box__content">
                {element.description.substring(0, 165) + " . . ."}
              </div>
              <div class="box_bottom">
                <Link
                  to={`/blog/${element.title.replaceAll(" ", "-")}/post/`}
                  onClick={() =>
                    window.localStorage.setItem("main_blog_id", element.id)
                  }
                >
                  <div class="read__more">
                    Read more <i class="bx bxs-right-arrow"></i>
                  </div>
                </Link>
                <p class="box_date">{formatDate(element.created_at)}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <Loading />
      )}
    </section>
  );
};

export default Blogs;
