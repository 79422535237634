import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const TrainingPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const benefitList = [
    {
      icon: `fa fa-graduation-cap`,
      title: "Industry-Ready Curriculum",
      description:
        "Our hands-on curriculum is meticulously crafted to elevate you from a novice to a skilled industry professional",
    },
    {
      icon: `fas fa-chalkboard-teacher`,
      title: "Industry-Experienced Instructors",
      description:
        "Learn from experts with extensive experience in your stack of choice",
    },
    {
      icon: `fas fa-building`,
      title: "State-of-the-Art Facilities",
      description:
        "Gain access to cutting-edge labs and equipment for practical training",
    },
    {
      icon: `fas fa-user-tie`,
      title: "Career Support",
      description:
        "Receive guidance and support in navigating career opportunities in the robotics industry",
    },
  ];

  const sections = [
    {
      title: "How long is the training?",
      content: `
                <p>
                    The training is an intensive one that runs for one month, therefore the training runs for 5days in a week, and class runs form 10am to 4pm daily.
                </p>`,
    },
    {
      title: "Is it an online or Physical training?",
      content:
        "It’s an in-depth physical training program for optimal results. If you’re insistent on online, you could kindly message us.",
    },
    {
      title: "What are the requirements for learning?",
      content: `<div>The requirements for learning are:</div>
                      <ol>
                        <li>No prior robotics experience required.</li>
                        <li>Basic knowledge of mathematics and physics recommended.</li>
                        <li>Ability to write and speak English proficiently</li>
                        <li>
                            Willingness to engage in hands-on projects and collaborate with
                            peers.
                        </li>
                        <li>A laptop with access to a stable internet connection</li>
                      </ol>`,
    },
    {
      title: "Where are you located?",
      content: "Automachoice Systems Training Centre, Itamaga, Ikorodu, Lagos.",
    },
    {
      title: "Can I pay on installment?",
      content: "Yes, Send a message to know more.",
    },
    {
      title: "Will I get certified?",
      content:
        "Absolutely yes, you will get a certificate of completion after the program.",
    },
    {
      title: "Can I register for multiple programmes at the same time?",
      content:
        "Due to the rigorous training requirements, you may only register for one program at a time. If you have already applied to or are currently enrolled in a program, you are ineligible to apply for another program.",
    },
    {
      title: "Do you offer private robotics training?",
      content:
        "Yes, we do offer both private and general teaching, depending on your choice.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleSection = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const [isSticky, setIsSticky] = useState(false);

  // Function to handle the scroll event and update state
  const handleScroll = () => {
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    scrollPosition < 600 ? setIsSticky(false) : setIsSticky(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //smooth scrolling

  const overviewRef = useRef(null);
  const curriculumRef = useRef(null);
  const whyRef = useRef(null);
  const progRef = useRef(null);
  const feeRef = useRef(null);
  const faqRef = useRef(null);
  const procedureRef = useRef(null);

  const scrollToSection = (ref) => {
    const topPosition = ref.current.offsetTop - 200;
    window.scrollTo({
      top: topPosition,
      behavior: "smooth",
    });
  };

  const programObject = [
    {
      overview:
        "Our Robotics Engineering program offers a comprehensive journey into the exciting world of robotics, blending theoretical knowledge with hands-on practical experience. Whether you're a beginner or seasoned enthusiast, our curriculum is designed to equip you with the skills needed to thrive in this rapidly advancing field.",
      curriculum: [
        {
          module: "Module 01 - Fundamentals of Robotics:",
          description:
            "Introduction to robotics principles, including mechanics, electronics, and programming. Understanding the importance and applications of robotics in various industries.",
        },
        {
          module: "Module 02 - Arduino Microcontroller Platform:",
          description:
            "Overview of the Arduino microcontroller platform, its hardware components, and programming environment. Learning how to interface sensors, actuators, and other peripherals with Arduino boards.",
        },
        {
          module: "Module 03 - Sensor Integration:",
          description:
            "Learning about various sensors commonly used in robotics applications, such as ultrasonic sensors, infrared sensors, temperature sensors, etc. Understanding how to integrate sensors with Arduino microcontrollers to gather data from the environment.",
        },
        {
          module: "Module 04 - Actuator Control:",
          description: `Exploring different types of actuators used in robotics, including servo motors, DC motors, stepper motors, etc. Learning how to control actuators using Arduino microcontrollers to achieve desired robotic movements and actions.`,
        },
        {
          module: "Module 05 - Robotics Programming with Arduino:",
          description: `Mastering programming concepts relevant to robotics, such as loops, conditionals, functions, and libraries. Writing and uploading Arduino sketches to implement robotic behaviors, motion control, sensor data processing, and decision-making algorithms.`,
        },
        {
          module: "Module 06 - Project-based Learning:",
          description: `Engaging in hands-on projects to apply theoretical knowledge and practical skills learned throughout the curriculum. Designing, building, and programming robotic systems using Arduino microcontrollers to solve real-world challenges and tasks.`,
        },
      ],
    },
    {
      overview:
        "Our Internet of Things (IoT) program is designed to introduce participants to the interconnected world of smart devices. From understanding the basics of IoT networks to deploying IoT solutions, our curriculum offers both theoretical insights and practical applications. This program is suitable for beginners as well as those looking to enhance their skills in the dynamic field of IoT.",
      curriculum: [
        {
          module: "Module 01 - Introduction to IoT:",
          description:
            "Understanding the concept of the Internet of Things (IoT), its principles, and its applications in various industries. Exploring the architecture of IoT systems, including devices, networks, and cloud platforms.",
        },
        {
          module: "Module 02 - IoT Connectivity Solutions:",
          description:
            "Explore various connectivity options that enable IoT devices to communicate. Understand the roles of WiFi, Bluetooth, Zigbee, and cellular connections in IoT deployments.",
        },
        {
          module: "Module 03 - Embedded Systems and Microcontrollers:",
          description:
            "Introduction to embedded systems and microcontrollers commonly used in IoT devices, such as Arduino, ESP32, Raspberry Pi, etc. Learning how to program microcontrollers to interact with sensors, actuators, and other hardware components.",
        },
        {
          module: "Module 04 - Sensor Technologies:",
          description:
            "Learning about different types of sensors commonly used in IoT applications, such as temperature sensors, humidity sensors, motion sensors, and more.",
        },
        {
          module: "Module 05 - Data Communication Protocols:",
          description:
            "Exploring communication protocols used in IoT systems, including MQTT, HTTP, CoAP, and WebSocket. Understanding the advantages and limitations of each protocol and their suitability for different IoT applications.",
        },
        {
          module: "Module 06 - Security and Privacy in IoT:",
          description:
            "Learning about security challenges in IoT systems, including data privacy, authentication, encryption, and secure communication. Exploring best practices and techniques for securing IoT devices, networks, and data against cyber threats and attacks.",
        },
      ],
    },
    {
      overview:
        "Our Frontend Web Development program is meticulously crafted to guide you through the essentials of creating visually engaging and functionally robust websites. Whether you are a complete novice or a professional looking to polish your skills, this program offers in-depth knowledge and hands-on practice in HTML, CSS, JavaScript, and modern frameworks and tools that are vital for any aspiring web developer.",
      curriculum: [
        {
          module: "Module 01 - HTML and CSS Foundations:",
          description:
            "Learn the building blocks of the web with HTML5 and CSS3. This module covers everything from basic page structure to advanced layout techniques with Flexbox and Grid, ensuring you can create responsive, accessible websites.",
        },
        {
          module: "Module 02 - JavaScript Essentials:",
          description:
            "Dive into JavaScript, the programming language that powers the web. Understand core concepts including variables, functions, and DOM manipulation, as well as modern ES6+ features and asynchronous programming to make your websites interactive and dynamic.",
        },
        {
          module: "Module 03 - HTML and CSS Foundations:",
          description:
            "Learn the building blocks of the web with HTML5 and CSS3. This module covers everything from basic page structure to advanced layout techniques with Flexbox and Grid, ensuring you can create responsive, accessible websites.",
        },
        {
          module: "Module 04 - JavaScript Essentials:",
          description:
            "Dive into JavaScript, the programming language that powers the web. Understand core concepts including variables, functions, and DOM manipulation, as well as modern ES6+ features and asynchronous programming to make your websites interactive and dynamic.",
        },
        {
          module: "Module 05 - HTML and CSS Foundations:",
          description:
            "Learn the building blocks of the web with HTML5 and CSS3. This module covers everything from basic page structure to advanced layout techniques with Flexbox and Grid, ensuring you can create responsive, accessible websites.",
        },
        {
          module: "Module 06 - JavaScript Essentials:",
          description:
            "Dive into JavaScript, the programming language that powers the web. Understand core concepts including variables, functions, and DOM manipulation, as well as modern ES6+ features and asynchronous programming to make your websites interactive and dynamic.",
        },
      ],
    },
    {
      overview:
        "Our Backend Web Development program is designed to equip you with the core skills necessary to build and maintain the server-side logic and databases that power complex websites and applications. From server setup to API development and database management, this program caters to beginners and intermediate developers alike, providing a deep dive into the backend technologies that form the backbone of web services.",
      curriculum: [
        {
          module: "Module 01 - Introduction to Web Development:",
          description:
            "Understanding the basics of web development, including client-server architecture, HTTP protocol, and RESTful API design principles. Introduction to backend development frameworks like Django and their role in building web applications.",
        },
        {
          module: "Module 02 - Python Programming Fundamentals:",
          description:
            "Mastering Python programming language fundamentals, including data types, control structures, functions, and object-oriented programming concepts. Hands-on exercises and projects to apply Python programming skills in backend development tasks.",
        },
        {
          module: "Module 03 - Django Web Framework:",
          description:
            "Overview of Django web framework architecture, including models, views, templates, and URL routing. Learning how to create Django projects, applications, and models to build database-driven web applications.",
        },
        {
          module: "Module 04 - Django ORM and Database Management:",
          description:
            "Understanding Django's Object-Relational Mapping (ORM) system and its integration with relational databases like SQLite, PostgreSQL, MySQL, etc. Performing database operations using Django ORM, including CRUD (Create, Read, Update, Delete) operations and query optimization techniques.",
        },
        {
          module:
            "Module 05 - RESTful API Development with Django Rest Framework (DRF):",
          description:
            "Introduction to Django Rest Framework (DRF) and its features for building RESTful APIs in Django. Learning how to create API endpoints, serialize data, handle requests, and perform authentication and authorization using DRF.",
        },
        {
          module: "Module 06 - Testing, Deployment, and Best Practices:",
          description:
            "Writing unit tests and integration tests for Django applications to ensure code quality and reliability. Deploying Django applications to production servers using platforms like Heroku, AWS, or Docker containers. Exploring best practices in backend engineering, including code organization, documentation, version control (e.g., Git), and collaboration tools.",
        },
      ],
    },
    {
      overview:
        "Our DevOps Engineering program bridges the gap between software development and operations, preparing you to streamline and automate processes to improve the efficiency and reliability of software deployment and scaling. This program is ideal for those interested in enhancing collaboration and productivity through continuous integration (CI) and continuous delivery (CD), infrastructure as code (IaC), and monitoring and telemetry.",
      curriculum: [
        {
          module: "Module 01 - Infrastructure as Code (IaC):",
          description:
            "Understanding the principles of Infrastructure as Code (IaC) and its importance in automating infrastructure management. Learning popular IaC tools such as Ansible, Terraform, or Chef for provisioning and configuring servers and infrastructure components..",
        },
        {
          module: "Module 02 - Web Server Configuration:",
          description:
            "Overview of web servers like Apache HTTP Server, Nginx, and their roles in serving web content. Hands-on experience with installing, configuring, and securing web servers to host web applications and static content.",
        },
        {
          module: "Module 03 - Containerization and Orchestration:",
          description:
            "Introduction to containerization technologies like Docker and container orchestration platforms like Kubernetes. Learning how to containerize web applications, manage containerized environments, and deploy applications at scale using Kubernetes.",
        },
        {
          module:
            "Module 04 - Continuous Integration and Continuous Deployment (CI/CD):",
          description:
            "Understanding the concepts of Continuous Integration (CI) and Continuous Deployment (CD) in the software development lifecycle. Implementing CI/CD pipelines using tools like Jenkins, GitLab CI/CD, or GitHub Actions for automating the build, test, and deployment processes.",
        },
        {
          module: "Module 05 - Monitoring and Logging:",
          description:
            "Overview of monitoring tools like Prometheus, Grafana, and logging solutions like ELK Stack (Elasticsearch, Logstash, Kibana). Setting up monitoring and logging infrastructure to monitor server performance, detect issues, and troubleshoot problems in real-time.",
        },
        {
          module: "Module 06 - Security and Compliance:",
          description:
            "Learning about security best practices for web servers and infrastructure, including firewall configuration, SSL/TLS certificate management, and access control. Understanding compliance requirements such as GDPR, HIPAA, or PCI-DSS and implementing measures to ensure compliance in server configuration and management.",
        },
      ],
    },
    {
      overview:
        "Our 3D CAD Modeling program is designed to turn aspiring designers into skilled CAD professionals, capable of modeling complex designs with precision and efficiency. This program covers a range of tools and techniques in CAD software such as AutoCAD, SolidWorks, and Fusion 360, suited for various industries including manufacturing, engineering, and product design.",
      curriculum: [
        {
          module: "Module 01 - Introduction to 3D CAD Modeling:",
          description:
            "Understanding the fundamentals of Computer-Aided Design (CAD) and its role in product design and development. Learning basic concepts such as sketches, features, dimensions, and constraints in 3D modeling.",
        },
        {
          module: "Module 02 - CAD Software Tools:",
          description:
            "Overview of popular CAD software tools such as SolidWorks, AutoCAD, Fusion 360, or CATIA. Hands-on experience with using CAD software to create 3D models, assemblies, and drawings.",
        },
        {
          module: "Module 03 - Parametric Modeling:",
          description:
            "Understanding parametric modeling principles and their importance in designing flexible and easily modifiable 3D models. Learning how to create parametric features, apply dimensions and constraints, and update designs dynamically.",
        },
        {
          module: "Module 04 - Assembly Modeling:",
          description:
            "Introduction to assembly modeling techniques for creating complex assemblies of parts and components. Learning how to assemble, mate, and constrain components within an assembly, and analyze their interactions.",
        },
        {
          module: "Module 05 - Simulation and Analysis:",
          description:
            "Overview of simulation and analysis tools for validating and optimizing 3D models. Performing finite element analysis (FEA), motion analysis, and other simulations to evaluate the performance and behavior of designs.",
        },
        {
          module: "Module 06 - Collaborative Design and Version Control:",
          description:
            "Understanding collaborative design workflows and tools for sharing and collaborating on CAD models within a team. Learning about version control systems such as Git and their application in managing changes and revisions in CAD models.",
        },
      ],
    },
  ];

  const programId = window.localStorage.getItem("training_id");
  return (
    <div className="training-page">
      <aside id="stickyDiv" className={isSticky ? "fixed-top" : "aside-div"}>
        <ul>
          <li onClick={() => scrollToSection(overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(curriculumRef)}>Curriculum</li>
          <li onClick={() => scrollToSection(whyRef)}>
            Why Automachoice Systems?
          </li>
          <li onClick={() => scrollToSection(progRef)}>Program Requirements</li>
          <li onClick={() => scrollToSection(feeRef)}>Training Fees</li>
          <li onClick={() => scrollToSection(procedureRef)}>
            Procedures For Registration
          </li>
          <li onClick={() => scrollToSection(faqRef)}>FAQs</li>
        </ul>
      </aside>
      <main className={isSticky ? "main-top" : ""}>
        <section ref={overviewRef} className="overview">
          <div className="training-heading">
            <h4>Overview</h4>
            <div className="bottom__border"></div>
          </div>
          <p>
            {programObject[programId].overview}
            <br />
            <br />
            Gain the essential skills and hands-on experience required to excel
            as a global tech talent. Our expert instructors bring real-world
            industry knowledge to the forefront, ensuring a beginner-friendly,
            practical, and project-based learning approach. Become a part of our
            dynamic tech community and unlock limitless opportunities for growth
            and success in the ever-evolving world of technology.
          </p>
        </section>
        <section ref={curriculumRef} className="Curriculum">
          <div className="training-heading">
            <h4>Curriculum</h4>
            <div className="bottom__border"></div>
          </div>
          <ul>
            {programObject[programId].curriculum.map((element) => (
              <li>
                <span>{element.module}</span>
                <br /> {element.description}
              </li>
            ))}
          </ul>
        </section>
        <section ref={whyRef} className="learn">
          <div className="training-heading">
            <h4>Why Automachoice Systems?</h4>
            <div className="bottom__border"></div>
          </div>
          <div className="benefits">
            {benefitList.map((element) => (
              <div className="benefit-card">
                <i className={element.icon}></i>
                <h4>{element.title}</h4>
                <p>{element.description}</p>
              </div>
            ))}
          </div>
        </section>
        <section ref={progRef} className="program-requirements">
          <div className="training-heading">
            <h4>Program Requirements?</h4>
            <div className="bottom__border"></div>
          </div>
          <div className="req_list">
            <ul>
              <li>No prior experience required.</li>
              <li>
                Basic knowledge of mathematics and physics recommended for
                Robotics Engineering and IoT stack.
              </li>
              <li>Ability to write and speak English proficiently</li>
              <li>
                Willingness to engage in hands-on projects and collaborate with
                peers.
              </li>
              <li>A laptop with access to a stable internet connection</li>
            </ul>
          </div>
        </section>
        <section ref={feeRef} className="training-fees">
          <div className="training-heading">
            <h4>Training Fees</h4>
            <div className="bottom__border"></div>
          </div>
          <div className="fees_box">
            <div className="fees_fee">
              <h4>&#8358;100,000</h4>
              <h5>&#8358;200,000</h5>
            </div>
            <div className="fees_info">
              <p>
                This is a One Time Fee tha covers the training for one month and
                this fee applies to all stacks
              </p>
              <Link to="/event/register">
                <button class="button__red">Register</button>
              </Link>
            </div>
          </div>
        </section>
        <section
          ref={procedureRef}
          className="event-description"
          style={{ paddingLeft: "0px", width: "100%" }}
        >
          <div className="training-heading">
            <h4>Procedures For Registration</h4>
            <div className="bottom__border"></div>
          </div>
          <div className="procedure">
            <ol>
              <li>
                Make payment into the designated account below with the
                description name "CODING AND ROBOTICS 2024":
                <p>
                  <strong>
                    ACCOUNT NAME: <span>AUTOMACHOICE SYSTEMS LTD.</span>
                  </strong>
                </p>
                <p>
                  <strong>
                    ACCOUNT NUMBER: <span>1221383853</span>
                  </strong>
                </p>
                <p>
                  <strong>
                    BANK NAME: <span>ZENITH BANK</span>
                  </strong>
                </p>
                <i>
                  <p>
                    <span>
                      <strong>NOTE:</strong> Obtain Payment Receipt from your
                      financial institution as a copy upload is requisite for
                      completion of registration.
                    </span>
                  </p>
                </i>
              </li>
              <li>
                After making payment, return to this page and click on the
                "Register" button below, to proceed.
              </li>
              <li>
                Fill all required fields, upload your proof of payment and
                upload Passport Photograph of participant(s) to be registered.
              </li>
              <li>
                Upon successful registration, further updates will be
                communicated you via the email address you provided.
              </li>
            </ol>
          </div>
          <br />
        </section>
        <section ref={faqRef} className="faq-section training-faq">
          <div className="training-heading">
            <h4>Frequently Asked Questions</h4>
            <div className="bottom__border"></div>
          </div>
          <div className="questions">
            {sections.map((section, index) => (
              <div
                key={index}
                className={`accordion-main ${openIndex === index ? "content-visible" : ""}`}
                style={{
                  height:
                    openIndex === index
                      ? index === 2
                        ? "238px"
                        : "180px"
                      : "64px",
                  transition: "height 300ms ease-in-out",
                }}
              >
                <div
                  className="accordion-header"
                  onClick={() => toggleSection(index)}
                >
                  <FontAwesomeIcon
                    icon={openIndex === index ? faCaretUp : faCaretRight}
                    className="caret-icon"
                  />
                  <span>{section.title}</span>
                </div>
                <div
                  ref={(el) => (contentRefs.current[index] = el)}
                  className="accordion-content"
                  style={{
                    height:
                      openIndex === index
                        ? `${contentRefs.current[index]?.scrollHeight}px`
                        : "0px",
                    overflow: "hidden",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: section.content }} />
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default TrainingPage;
