import { bone } from "fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const CourseCard = ({ course }) => {
  return (
    <div className="course__box">
      <div className="image">
        <img src={course.image} alt="" srcset="" />
      </div>
      <div>
        <div className="box__header">
          <div className="txt">
            {course.title}
            <div className="training_bottom__border"></div>
          </div>
          <i className="bx bxs-network-chart"></i>
        </div>
        <div className="box__content">
          {course.description.substring(0, 150) + " . . ."}
        </div>
        <Link
          to={`/trainings/${course.url}`}
          onClick={() => window.localStorage.setItem("training_id", course.id)}
        >
          <div class="read__more">
            Read more <i class="bx bxs-right-arrow"></i>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CourseCard;
