import React, { useState, useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(0);

  const setNone = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const sendMail = async () => {
    setNone();
    const body = {
      name: name,
      phone: phone,
      email: email,
      message: message,
    };

    const isNullOrEmpty = (value) =>
      value === null || value === undefined || value === "";

    for (const key in body) {
      if (body.hasOwnProperty(key) && isNullOrEmpty(body[key])) {
        setErrorMessage(`${key.replaceAll("_", " ")} cannot be blank or empty`);
        return null;
      }
    }

    setIsLoading(true);

    const url = `${process.env.REACT_APP_BASE_URL}/send_mail/`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ApiAuthorization: process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();
    setSuccess(response.status);
    if (response.status === 200) {
      setIsLoading(false);
      setSuccessMessage(data["message"]);
      setErrorMessage("");
    } else {
      setIsLoading(false);
      setSuccessMessage("");
      setErrorMessage(data["message"]);
    }
  };

  return (
    <div>
      <div className="contact__tablet">
        <div className="tablet__screen">
          <div className="contact__message">
            Please fill the form below if you would like to contact us to
            inquire about our services or any other official purpose. You can
            find our contact details below as well
          </div>

          <div className="contact__details">
            <div className="phone">
              <i className="bx bxs-phone"></i>
              <div className="text">+234 903 207 5159</div>
            </div>
            <div className="address">
              <i className="bx bx-map"></i>
              <div className="text">
                8/10, God's glory Avenue, Good luck estate , Agbede Olunla, off
                Agric - Isawo Road, Ikorodu, Lagos
              </div>
            </div>
          </div>

          <div>
            <div className="input__row">
              <div className="input__group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="contact__field"
                  placeholder="Name"
                />
              </div>

              <div className="input__group">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="contact__field"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Phone"
                />
              </div>
            </div>

            <div className="input__row">
              <div className="input__group">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="contact__field"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email address"
                />
              </div>
            </div>

            <div className="input__row">
              <div className="input__group">
                <textarea
                  id="message"
                  placeholder="Message"
                  className="contact__field"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  name="message"
                ></textarea>
              </div>
            </div>

            {isLoading ? (
              <div className="process">
                <div id="loader"></div> Submitting your details...
              </div>
            ) : (
              <></>
            )}
            {success === 200 ? (
              <div className="log success__log">{successMessage}</div>
            ) : (
              <></>
            )}
            {errorMessage.length > 0 ? (
              <div className="log error__log">
                <i class="bx bx-x"></i>
                {errorMessage}
              </div>
            ) : (
              <></>
            )}

            <div className="button__container">
              <button onClick={sendMail}>Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
