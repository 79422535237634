import React, { useEffect } from "react";
import CourseCard from "../components/CourseCard";
import robotic from "../img/trainings/cover_image/robotics.jpeg";
import iot from "../img/trainings/cover_image/iot.jpeg";
import frontend from "../img/trainings/cover_image/frontend.jpeg";
import backend from "../img/trainings/cover_image/backend.jpeg";
import devops from "../img/trainings/cover_image/devops.jpeg";
import cad from "../img/trainings/cover_image/3dcad.jpeg";

const Trainings = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const programs = [
    {
      id: 0,
      title: "Robotics Engineering",
      description:
        "Our Robotics Engineering Training program provides a comprehensive exploration into the captivating realm of robotics, covering both hardware and software aspects. Participants will delve into fundamental concepts while gaining hands-on experience through engaging projects.",
      image: robotic,
      url: "robotics-engineering",
    },
    {
      id: 1,
      title: "Internet of Things (IoT)",
      description:
        "Our Internet of Things (IoT) Training program provides a comprehensive journey into the interconnected world of smart devices, encompassing both theoretical understanding and hands-on experience. Participants will explore the fundamental principles of IoT architecture, ecosystems, and key technologies through engaging projects.",
      image: iot,
      url: "iot",
    },
    {
      id: 2,
      title: "Frontend Web Development",
      description:
        "Our Frontend Web Development Training program is designed to equip you with both the foundational skills and advanced techniques necessary for creating responsive and interactive websites.",
      image: frontend,
      url: "frontend-web-development",
    },
    {
      id: 3,
      title: "Backend Web Development",
      description:
        "Our Backend Web Development Training program delves into the intricacies of server-side development, focusing on essential concepts and tools required to build robust and scalable web applications. Participants will gain proficiency in server setup, database management, and backend frameworks, empowering them to craft efficient and secure server-side logic for modern web services.",
      image: backend,
      url: "backend-web-development",
    },
    {
      id: 4,
      title: "Devops Engineeering",
      description:
        "Our DevOps Engineering Training program delves into the dynamic intersection of software development and IT operations, providing comprehensive coverage of essential DevOps practices and tools. Participants will gain practical skills in automation, continuous integration and delivery (CI/CD), infrastructure as code (IaC), and monitoring, empowering them to streamline software delivery pipelines and enhance collaboration across development and operations teams.",
      image: devops,
      url: "devops-engineering",
    },
    {
      id: 5,
      title: "3D CAD Modeling",
      description:
        "Our 3D CAD Modeling Training program immerses participants in the exciting world of computer-aided design, covering essential concepts and tools necessary for creating precise and intricate 3D models. With a focus on popular CAD software such as AutoCAD, SolidWorks, and Fusion 360, participants will develop the skills needed to bring their design ideas to life in various industries including manufacturing, engineering, and product design.",
      image: cad,
      url: "3d-cad-modeling",
    },
  ];

  return (
    <section className="trainings">
      <div className="training-section">
        <p className="title">
          <span>Empower Your Future:</span> Unlock Tech Mastery & Transform Your
          Career Path
        </p>
        <div className="description">
          <p>
            Gain the essential skills and hands-on experience required to excel
            as a global tech talent. Our expert instructors bring real-world
            industry knowledge to the forefront, ensuring a beginner-friendly,
            practical, and project-based learning approach.
          </p>
        </div>
        <div className="brief">
          <i class="bx bxs-right-arrow"></i>
          <p>CORE TRAINING PROGRAMS</p>
          <i class="bx bxs-left-arrow"></i>
        </div>
      </div>
      <div className="programs">
        {programs.map((element) => (
          <CourseCard course={element} />
        ))}
      </div>
    </section>
  );
};

export default Trainings;
